import React from 'react';
import { withMedia } from 'react-media-query-hoc';

import { AppMenu } from '../../menu/landing';
import Hero from './Hero';
import Content from './Content';
import Footer from './Footer';

class Home extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isMobile: props.media.mobile || props.media.tablet
    };
  }

  componentDidMount() {
    if (this.props.media.mobile || this.props.media.tablet) {
      this.setState({ isMobile: true });
    }
  }

  render() {
    return (
      <div>
        <AppMenu></AppMenu>
        <Hero isMobile={this.state.isMobile} />
        {/* <Content isMobile={this.state.isMobile}></Content> */}
        <Footer isMobile={this.state.isMobile} />
      </div>
    );
  }
}

export default withMedia(Home);
