import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Menu, Dropdown, Icon } from 'antd';
import { i18n } from '../../i18n';
import g from '../../styles/global';

const styles = {
  container: {
    height: '100%',
    ...g.layout.flexVertical,
    ...g.layout.flexCenter,
    alignItems: 'center',
    position: 'relative',
    top: '0.125em'
  },
  dropdown: {
    height: '100%'
  },
  adjustMenuItem: { paddingBottom: g.global.baseline / 4 },
  language: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexCenter,
    ...g.layout.alignCenter,
    height: '100%',
    fontSize: g.global.baseline * 1.5
  },
  icon: {
    paddingLeft: g.global.baseline / 2
  }
};

export default props => {
  const [language, setLanguage] = useState(props.language);

  const menuItemProps = key => ({
    key: key,
    style: language === key ? { color: '#1890ff' } : {}
  });

  const menu = (
    <Menu
      theme="dark"
      onClick={async ({ key }) => {
        await i18n.changeLanguage(key);
        setLanguage(key);
      }}
    >
      <Menu.Item {...menuItemProps('en')}>English</Menu.Item>
      <Menu.Item {...menuItemProps('pt')}>Português</Menu.Item>
    </Menu>
  );
  return (
    <div style={styles.container}>
      <Helmet>
        <style type="text/css">
          {`.ant-dropdown .ant-dropdown-menu {
            padding: 12px;
          }`}
        </style>
      </Helmet>
      <Dropdown
        style={styles.dropdown}
        trigger={['hover']}
        overlay={menu}
        placement="bottomCenter"
      >
        <span style={styles.language}>
          <span style={styles.adjustMenuItem}>{language}</span>
          <Icon style={styles.icon} type="down" />
        </span>
      </Dropdown>
    </div>
  );
};
