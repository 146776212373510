import React from 'react';
import { withTranslation } from 'react-i18next';
import g from '../../styles/global';

const styles = {
  container: {
    ...g.layout.flexCenter,
    ...g.layout.flexHorizontal,
    ...g.layout.alignCenter,
    backgroundColor: g.colors.background.foodprintsGreenDark,
    height: '80px',
    overflow: 'hidden',
    color: '#dddddd'
  },
  link: {
    color: '#dddddd'
  },
  padding: {
    paddingRight: '12px'
  }
};

class Footer extends React.PureComponent {
  render() {
    const t = this.props.t;
    return (
      <div style={styles.container}>
        <span style={styles.padding}>
          ©2019{' '}
          <a
            style={{ ...styles.link, ...styles.padding }}
            href="https://perfectorange.pt"
          >
            Perfect Orange
          </a>{' '}
          <span>{t('footer.rights')}</span>
        </span>
      </div>
    );
  }
}

export default withTranslation()(Footer);
