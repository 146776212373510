import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../context/AppContextBase';
import { Helmet } from 'react-helmet-async';
import { Layout, Table, Divider, Tag } from 'antd';
import { mrg } from '../../common/util';
import globalStyles from '../../styles/global';
//import Menu from '../components/Menu';
import moment from 'moment';

import { getAllSurveys } from '../../network/foodAndSurvey';
const { Content } = Layout;

const styles = {
  header: {
    height: globalStyles.global.baseline * 4,
    backgroundColor: globalStyles.colors.background.pink,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter
  },
  link: {
    textAlign: 'center',
    fontSize: 36,
    fontWeight: 100,
    borderBottom: '2px solid ' + globalStyles.colors.background.pink
  },
  paragraph: {
    textAlign: 'center',
    fontSize: 96,
    fontWeight: 700,
    marginBottom: globalStyles.global.baseline
  },
  note: {
    textAlign: 'center',
    fontSize: 48,
    fontWeight: 100,
    marginBottom: globalStyles.global.baseline
  }
};

const surveyList = [
  {
    key: '1',
    date: '28-12-2018', //moment(value).format('YYYY-MM-DD HH:mm'),
    number_of_plates: 3,
    country: 'Portugal',
    age: ['24_36'],
    answers: 1
  }
];

const data = [
  {
    key: '1',
    // name: 'John Brown',
    number_of_answers: 10,
    date: moment(Date.now()).format('YYYY-MM-DD HH:mm')
    //tags: ['nice', 'developer'],
    //action:
  },
  {
    key: '2',
    // name: 'John Brown',
    number_of_answers: 10,
    date: moment(Date.now()).format('YYYY-MM-DD HH:mm')
    //tags: ['nice', 'developer']
  }
];

class SurveyList extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = { data: null };
  }

  async componentDidMount() {
    let surveyRequest = await getAllSurveys();
    console.log('surveyRequest: ', surveyRequest);
    let tableSurveyList = surveyRequest.map(eachSurvey => {
      return {
        date: eachSurvey.date,
        number_of_answers: eachSurvey.answers.length,
        number_of_plates: eachSurvey.answers.filter(
          answer => answer.type === 'plates'
        ).length,
        key: eachSurvey._id
      };
    });

    //Dummy surveys :
    // for (let i = 0; i <= 40; i++) {
    //   tableSurveyList.push({
    //     date: Date.now(),
    //     number_of_answers: 10 + i,
    //     number_of_plates: 3,
    //     key: i + 10
    //   });
    // }
    console.log('processedSurveyList: ', tableSurveyList);

    this.setState({
      data: tableSurveyList
    });
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Survey List</title>
        </Helmet>
        <Layout
          style={{
            margin: 0,
            padding: 0,
            width: '100%'
            // minHeight:
            //   'calc(100vh - ' + globalStyles.global.baseline * 4 + 'px)'
          }}
        >
          <Content
            // style={mrg([
            //   globalStyles.layout.flexVertical
            //   //globalStyles.layout.flexCenter
            // ])}
            style={{ padding: '20px' }}
          >
            {/* <p style={styles.paragraph}>Nothing to see here</p> */}
            <p style={styles.note}>Survey List</p>

            {this.state.data ? (
              <Table
                columns={columns}
                dataSource={this.state.data}
                pagination={false}
                scroll={{ y: 500 }}
              />
            ) : null}
          </Content>
        </Layout>
      </>
    );
  }
}
/*
    key: '1',
    date: moment(value).format('YYYY-MM-DD HH:mm'),
    number_of_plates: 3,
    country: 'Portugal',
    age: ['24_36'],
    answers:1
*/

const columns = [
  {
    title: 'Survey Date',
    dataIndex: 'date',
    key: 'date'
    //render: text => <a>{surveyList.}</a>
  },
  {
    title: 'Number of plates',
    dataIndex: 'number_of_plates',
    key: 'number_of_plates'
  },
  {
    title: 'Number of answers',
    dataIndex: 'number_of_answers',
    key: 'number_of_answers'
  }
  //   {
  //     title: 'Ages',
  //     key: 'test',
  //     dataIndex: 'tags',
  //     render: surveyList => (
  //       <span>
  //         <Tag color={'#0ff'} key={123}>
  //           {'test survey tag'}
  //         </Tag>
  //       </span>
  //     )
  //   },
  // {
  //   title: 'See Survey (Not active)',
  //   key: 'action',
  //   render: (text, record) => (
  //     <span>
  //       <a>Details {record.name}</a>
  //       <Divider type="vertical" />
  //       <a>Delete</a>
  //     </span>
  //   )
  // }
];

export default SurveyList;
