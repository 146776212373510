//eslint-disable-next-line no-unused-vars
//import { address } from '../common/variables';
import { handleResponse, authenticate } from './common';
let address;
if (process.env.NODE_ENV === 'production') {
  address = 'https://urbanfoodprints.com/api';
} else {
  address = 'http://localhost:3000';
}
export async function fetchUserDataIfAuthenticated() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(address + '/users/' + userInfo.attributes.sub, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });

  return await handleResponse(response);
}
