import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Icon, Input, Button, Row, Col, message } from 'antd';
import op from 'object-path';

import AppContext from '../../context/AppContextBase';
import DetailedMessage from '../../components/util/DetailedMessage';
import './Login.css';

import logo from '../../img/password.svg';

const FormItem = Form.Item;

class Login extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);

    let redirect = op.get(this.props, 'location.state.redirect');
    if (!redirect) {
      redirect = this.props.redirect;
    }
    if (!redirect) {
      redirect = '/foodprints';
    }
    this.state = {
      signState: 'signIn',
      username: 'test',
      password: '',
      securityCode: '',
      submitRecover: false,
      renewPassword: false,
      confirmDirty: false,
      redirect: redirect,
      loading: true
    };
  }

  async componentDidMount() {
    const user = await this.context.currentUser();
    if (user) {
      this.setState({ signState: 'redirect' });
    } else {
      this.setState({ loading: false });
    }
  }

  renderLogo() {
    return (
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 6, offset: 6 }}>
        <div
          style={{
            // display: 'flex',
            backgroundImage: 'url(' + logo + ')',
            width: '260px',
            height: '140px',
            //backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'
          }}
        />
      </Col>
    );
  }

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('renew-password')) {
      callback('Passwords do not match');
    } else {
      callback();
    }
  };

  checkConfirm = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['renew-confirm'], { force: true });
    }
    callback();
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    switch (this.state.signState) {
      case 'signIn':
        return this.renderSignIn();
      case 'forgotRecover':
        return this.renderForgotRecover();
      case 'renewPassword':
        return this.renderRenewPassword();
      case 'redirect':
        return <Redirect to={this.state.redirect}></Redirect>;
      //break;
      default:
        this.renderSignIn();
    }
  }

  renderSignIn() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="Login-page">
        <Row type="flex" align="middle" style={{ height: '300px' }}>
          {this.renderLogo()}
          <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6 }}>
            {/*<Form onSubmit={this.handleSubmit} className="login-form">*/}
            <Form className="login-form">
              <FormItem>
                {getFieldDecorator('signin-username', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Input
                    disabled={this.state.loading}
                    id="signin-username"
                    prefix={
                      <Icon
                        type="user"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    placeholder="Username or e-mail"
                    onChange={e => {
                      //log(e.target.value);
                      return this.setState({
                        username: e.target.value.trim()
                      });
                    }}
                  />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('signin-password', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ]
                })(
                  <Input.Password
                    id="signin-password"
                    disabled={this.state.loading}
                    prefix={
                      <Icon
                        type="lock"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    placeholder="Password"
                    onChange={e => {
                      //log(e.target.value);
                      return this.setState({
                        password: e.target.value.trim()
                      });
                    }}
                  />
                )}
              </FormItem>
              <FormItem>
                {/* {getFieldDecorator('signin-remember')(
                  <Checkbox checked={true} id="signin-remember">
                    Remember me
                  </Checkbox>
                )} */}
                <Button
                  type="secondary"
                  loading={this.state.loading}
                  className="login-form-button"
                  //setFieldsValue={this.state.username}
                  onClick={() => {
                    const errorOut = async e => {
                      await this.context.logOut();
                      message.error(
                        e && e.message
                          ? e.message
                          : 'Unexpected Error. Please try again in a few moments.'
                      );
                      this.setState({ loading: false });
                      return;
                    };

                    this.props.form.validateFields(async (err, values) => {
                      if (!err) {
                        this.setState({ loading: true });
                        let awsUser;
                        try {
                          /* login in AWS */
                          awsUser = await this.context.logInAws(
                            this.state.username,
                            this.state.password
                          );
                          if (!awsUser) {
                            throw new Error(
                              'Error authenticating in identity provider'
                            );
                          }
                        } catch (e) {
                          errorOut(e);
                          return;
                        }
                        /* Check first time challenge */
                        if (awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
                          message.success(
                            'First time signing in? Please renew you password'
                          );
                          this.setState({
                            password: '',
                            signState: 'renewPassword',
                            userParams: awsUser
                          });
                        } else {
                          /* Otherwise, authenticate in BE */
                          try {
                            await this.context.loginBackend(awsUser);
                            this.setState({
                              signState: 'redirect',
                              loading: false
                            });
                            return;
                          } catch (err) {
                            /* Failed ? Maybe it's first login. Register in BE. */
                            let registerResult;
                            try {
                              registerResult = await this.context.registerInBackend(
                                awsUser
                              );
                            } catch (e) {
                              errorOut(e);
                              return;
                            }
                            if (registerResult) {
                              this.setState({
                                signState: 'redirect'
                              });
                            }
                          }
                        }

                        this.setState({ loading: false });
                      }
                    });
                  }}
                >
                  Log in
                </Button>
              </FormItem>
              <a
                href="#!"
                className="login-form-forgot"
                onClick={() =>
                  this.setState({
                    signState: 'forgotRecover'
                  })
                }
              >
                Forgot Password?
              </a>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
  renderForgotRecover() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="Login-page">
        <Row type="flex" align="middle" style={{ height: '300px' }}>
          {this.renderLogo()}
          <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6 }}>
            <Form onSubmit={this.handleSubmit} className="login-form">
              {!this.state.submitRecover ? (
                <>
                  <FormItem>
                    {getFieldDecorator('recover-username', {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your email!'
                        }
                      ]
                    })(
                      <Input
                        disabled={this.state.loading}
                        id="recover-username"
                        prefix={
                          <Icon
                            type="user"
                            style={{
                              color: 'rgba(0,0,0,.25)'
                            }}
                          />
                        }
                        placeholder="Email"
                        onChange={e => {
                          //log(e.target.value);
                          return this.setState({
                            username: e.target.value.trim()
                          });
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    <Button
                      loading={this.state.loading}
                      type="primary"
                      className="login-form-button"
                      onClick={() => {
                        this.props.form.validateFields(async (err, values) => {
                          if (!err) {
                            this.setState({ loading: true });
                            await this.context
                              .forgotPassword(this.state.username)
                              .then(data => {
                                this.setState({
                                  submitRecover: true
                                });
                              })
                              .catch(e => {
                                DetailedMessage.error(
                                  'Unexpected Error. Please try again',
                                  e
                                );
                              });
                            this.setState({ loading: false });
                          }
                        });
                      }}
                    >
                      Send security code
                    </Button>
                    Or{' '}
                    <Button
                      loading={this.state.loading}
                      type="link"
                      onClick={() =>
                        this.setState({
                          signState: 'signIn'
                        })
                      }
                    >
                      back to login.
                    </Button>
                  </FormItem>{' '}
                </>
              ) : (
                <>
                  <FormItem>
                    {getFieldDecorator('recover-code', {
                      rules: [
                        {
                          required: true,
                          message: 'Type the code received on your email.'
                        }
                      ]
                    })(
                      <Input
                        disabled={this.state.loading}
                        id="recover-code"
                        prefix={
                          <Icon
                            type="lock"
                            style={{
                              color: 'rgba(0,0,0,.25)'
                            }}
                          />
                        }
                        type="username"
                        placeholder="Security Code"
                        onChange={e => {
                          //log(e.target.value);
                          return this.setState({
                            securityCode: e.target.value.trim()
                          });
                        }}
                      />
                    )}
                  </FormItem>
                  <FormItem>
                    {getFieldDecorator('recover-password', {
                      rules: [
                        {
                          required: true,
                          message: 'Please type your new password!'
                        }
                      ]
                    })(
                      <Input
                        disabled={this.state.loading}
                        id="recover-password"
                        prefix={
                          <Icon
                            type="lock"
                            style={{
                              color: 'rgba(0,0,0,.25)'
                            }}
                          />
                        }
                        type="password"
                        placeholder="Password"
                        onChange={e => {
                          return this.setState({
                            password: e.target.value.trim()
                          });
                        }}
                      />
                    )}
                  </FormItem>

                  <FormItem>
                    <Button
                      loading={this.state.loading}
                      style={{
                        backgroundColor: '#3D9970',
                        borderColor: 'white',
                        color: 'white'
                      }}
                      type="primary"
                      className="login-form-button"
                      onClick={() => {
                        this.props.form.validateFields(async (err, values) => {
                          if (!err) {
                            this.setState({ loading: true });
                            await this.context
                              .forgotPasswordSubmit(
                                this.state.username,
                                this.state.securityCode,
                                this.state.password
                              )
                              .then(async awsUser => {
                                const isAuth = await this.context.loginBackend(
                                  awsUser
                                );
                                if (isAuth) {
                                  this.setState({ signState: 'redirect' });
                                }
                              })
                              .catch(e => {
                                DetailedMessage.error(
                                  'Unexpected Error. Please try again',
                                  e
                                );
                              });
                            this.setState({ loading: false });
                          }
                        });
                      }}
                    >
                      Confirm new password
                    </Button>
                  </FormItem>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  renderRenewPassword() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="Login-page">
        <Row type="flex" align="middle" style={{ height: '300px' }}>
          {this.renderLogo()}
          <Col xs={{ span: 20, offset: 2 }} lg={{ span: 6 }}>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem key="renew-password">
                {getFieldDecorator('renew-password', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please choose your password'
                    },
                    {
                      validator: this.checkConfirm
                    }
                  ]
                })(
                  <Input.Password
                    disabled={this.state.loading}
                    id="renew-password"
                    prefix={
                      <Icon
                        type="lock"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    type="password"
                    placeholder="Password"
                    onBlur={this.handleConfirmBlur}
                    onChange={e => {
                      return this.setState({
                        password: e.target.value.trim()
                      });
                    }}
                  />
                )}
              </FormItem>
              <FormItem key="renew-confirm">
                {getFieldDecorator('renew-confirm', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password'
                    },
                    {
                      validator: this.checkPassword
                    }
                  ]
                })(
                  <Input.Password
                    disabled={this.state.loading}
                    id="renew-confirm"
                    prefix={
                      <Icon
                        type="lock"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    type="password"
                    placeholder="Confirm password"
                  />
                )}
              </FormItem>
              <FormItem>
                <Button
                  loading={this.state.loading}
                  style={{
                    backgroundColor: '#3D9970',
                    borderColor: 'white',
                    color: 'white'
                  }}
                  type="default"
                  className="login-form-button"
                  onClick={() => {
                    this.props.form.validateFields(async (err, values) => {
                      if (!err) {
                        this.setState({ loading: true });
                        try {
                          const awsUser = await this.context.completeNewPassword(
                            this.state.userParams,
                            this.state.password
                          );
                          const isAuth = await this.context.loginBackend(
                            awsUser
                          );
                          if (isAuth) {
                            this.setState({ signState: 'redirect' });
                            return;
                          }
                        } catch (e) {
                          DetailedMessage.error(
                            'Unexpected Error. Please try again',
                            e
                          );
                        }
                        this.setState({ loading: false });
                      }
                    });
                  }}
                >
                  Renew Password
                </Button>
                Or{' '}
                <Button
                  type="link"
                  loading={this.state.loading}
                  onClick={() => this.setState({ signState: 'signIn' })}
                >
                  back to Login.
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(Login);

export default WrappedNormalLoginForm;
