import React from 'react';
import { fetchUserDataIfAuthenticated } from '../network/userInfo';
import { firstTimeUser } from '../network/users';
import { Auth, Hub } from 'aws-amplify';
import AppContext from './AppContextBase';
import Permissions from './Permissions';

const defaultValues = { authenticated: false, userData: null, awsData: null };

class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.reset = this.reset.bind(this);
    this.getDefaultValues = this.getDefaultValues.bind(this);
    this.loginBackend = this.loginBackend.bind(this);
    this.signUp = this.signUp.bind(this);
    this.logOut = this.logOut.bind(this);
    this.logInAws = this.logInAws.bind(this);
    this.confirmSignUp = this.confirmSignUp.bind(this);
    this.resendSignUp = this.resendSignUp.bind(this);
    this.registerInBackend = this.registerInBackend.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.currentUser = this.currentUser.bind(this);
    this.forgotPasswordSubmit = this.forgotPasswordSubmit.bind(this);
    this.completeNewPassword = this.completeNewPassword.bind(this);
    this.checkPermission = this.checkPermission.bind(this);
    this.handleHubAuthCapsule = this.handleHubAuthCapsule.bind(this);
    this.helpers = this.helpers.bind(this);
    this.reset({ inConstructor: true });

    Hub.listen('auth', this.handleHubAuthCapsule, 'signOut');
  }

  async componentDidMount() {
    await this.currentUser();
  }

  async currentUser() {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: false
      });
      if (user) {
        return await this.loginBackend(user);
      }
    } catch (err) {
      this.setState({
        authenticated: false,
        userData: null,
        awsData: null
      });
      return null;
    }
  }

  async handleHubAuthCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      if (payload.event === 'signOut') {
        this.setState({ authenticated: false, userData: null, awsData: null });
      } else if (payload.errors) {
        let msg = '';
        for (let outer in payload.errors) {
          const innerErrors = payload.errors[outer].errors;
          if (innerErrors) {
            for (let inner in innerErrors) {
              msg += innerErrors[inner];
            }
            msg += '\n';
          }
        }
        console.warn('Authenticaton Error:', msg);
      }
    } else {
      // console.log('hub: ', payload);
    }
  }

  reset(options = {}) {
    if (options.inConstructor) {
      //eslint-disable-next-line react/no-direct-mutation-state
      this.state = this.getDefaultValues();
    } else {
      this.setState(this.getDefaultValues());
    }
  }

  async loginBackend(awsData) {
    let userData;
    try {
      const userRequest = await fetchUserDataIfAuthenticated();
      if (!userRequest || !(userRequest.result === 'OK')) {
        throw new Error('Failure getting user details from backend.');
      }
      userData = userRequest.data;
    } catch (err) {
      this.setState({
        userData: null
      });
      throw err;
    }
    this.setState({
      authenticated: true,
      userData: userData,
      awsData: awsData
    });

    return true;
  }

  async signUp(username, email, password, given_name, family_name) {
    return await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        given_name,
        family_name
      },
      validationData: [] //optional
    });
  }

  async logInAws(username, password) {
    try {
      const awsUser = await Auth.signIn(username, password);
      if (awsUser) {
        this.setState({
          authenticated: true,
          userData: null,
          awsData: awsUser
        });
        return awsUser;
      } else {
        throw new Error('Error logging in');
      }
    } catch (err) {
      this.setState({
        authenticated: false,
        userData: null,
        awsData: null
      });
      throw err;
    }
  }

  async logOut() {
    const result = await Auth.signOut();
    this.setState({ authenticated: false, userData: null, awsData: null });
    return result;
  }

  async confirmSignUp(username, code) {
    return await Auth.confirmSignUp(username, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      forceAliasCreation: true
    });
  }

  async registerInBackend(awsUser) {
    const user = {
      email: awsUser.attributes.email,
      firstName: awsUser.attributes.family_name,
      lastName: awsUser.attributes.given_name,
      id: awsUser.attributes.sub
    };

    const result = await firstTimeUser(user);
    this.setState({ userData: result.data });
    return result;
  }

  async resendSignUp(username) {
    return await Auth.resendSignUp(username);
  }

  async forgotPassword(username) {
    return await Auth.forgotPassword(username);
  }

  async forgotPasswordSubmit(username, securityCode, password) {
    return await Auth.forgotPasswordSubmit(username, securityCode, password);
  }

  async completeNewPassword(userParams, password) {
    return await Auth.completeNewPassword(userParams, password);
  }

  getDefaultValues() {
    return defaultValues;
  }

  checkPermission(scope) {
    return Permissions.check(scope, this.state.userData);
  }

  helpers() {
    return {
      getUserAttribute: this.getUserAttribute.bind(this),
      checkPermission: this.checkPermission.bind(this),
      logOut: this.logOut.bind(this),
      loginBackend: this.loginBackend.bind(this)
    };
  }

  getUserAttribute(attributeName) {
    switch (attributeName) {
      case 'role':
        return this.state.userData.role;
      case 'scope':
        return this.state.userData.permissions.scope;
      case 'department':
        return this.state.userData.permissions.department;
      default:
        return null;
    }
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          loginBackend: this.loginBackend,
          signUp: this.signUp,
          logInAws: this.logInAws,
          logOut: this.logOut,
          confirmSignUp: this.confirmSignUp,
          resendSignUp: this.resendSignUp,
          registerInBackend: this.registerInBackend,
          currentUser: this.currentUser,
          forgotPassword: this.forgotPassword,
          forgotPasswordSubmit: this.forgotPasswordSubmit,
          completeNewPassword: this.completeNewPassword,
          checkPermission: this.checkPermission,
          getUserAttribute: this.getUserAttribute,
          helpers: this.helpers
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

const AppContextConsumer = AppContext.Consumer;

export { AppContextProvider, AppContextConsumer };
