import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';

import AppContext from './context/AppContextBase';
import { i18nInit, i18n } from './i18n';
import loadResources from './i18n/loadResources';

import { withTranslation } from 'react-i18next';

import Landing from './pages/landing';
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';
import Loading from './components/util/Loading';

import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Settings from './pages/settings';
import Root from './pages/backoffice/Home';
import './style_overides.css';

Amplify.configure(awsConfig);
//const logger = new Logger('App');

class App extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { i18nLoaded: false };
  }

  async componentDidMount() {
    await i18nInit;
    await loadResources(i18n);
    this.setState({ i18nLoaded: true });
  }

  render() {
    return this.state.i18nLoaded ? (
      <Router>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route
            exact
            path="/login"
            render={props => <Login redirect="/foodprints" {...props}></Login>}
          />
          <Route exact path="/register" render={() => <Register></Register>} />
          <ProtectedRoute
            authenticated={!!this.context.state.userData}
            redirect="/login"
            path="/foodprints"
            component={Root}
          />
          {/* <ProtectedRoute
            authenticated={!!this.context.state.userData}
            redirect="/login"
            path="/settings"
            component={Settings}
          /> */}
        </Switch>
      </Router>
    ) : (
      <Loading></Loading>
    );
  }
}

App.contextType = AppContext;

export default withTranslation()(App);
