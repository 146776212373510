import React from 'react';
import AppContext from '../../../../context/AppContextBase';
import { Transfer, Table, Button, message } from 'antd';
import globalStyles from '../../../../styles/global';
import difference from 'lodash.difference';

import Loading from '../../../../components/util/Loading';
import DetailedMessage from '../../../../components/util/DetailedMessage';
import { putGroup } from '../../../../network/users';

const styles = {
  actionBox: {
    paddingTop: globalStyles.global.baseline,
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexEnd,
    ...globalStyles.layout.alignCenter
  }
};

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys
      };

      return (
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="middle"
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            }
          })}
        />
      );
    }}
  </Transfer>
);

const tableColumns = [
  {
    dataIndex: 'id',
    title: 'ID'
  },
  {
    dataIndex: 'name',
    title: 'Name'
  }
];

class ManagePermissions extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = { visible: true, data: null, targetKeys: [], loading: true };

    this.onChange = this.onChange.bind(this);
  }

  onChange = nextTargetKeys => {
    this.setState({ targetKeys: nextTargetKeys });
  };

  async componentDidMount() {
    //const response = await getClients();

    console.error(
      'pages/settings/groups/permissions: fake request, change me!'
    );
    const response = {
      result: 'OK',
      data: [
        { id: 'A', name: 'A' },
        { id: 'B', name: 'B' }
      ]
    };
    let ok = false;

    if (response && response.result === 'OK') {
      this.setState({
        data: response.data.docs ? response.data.docs : response.data
      });
      ok = true;
    } else {
      ok = false;
      DetailedMessage.error('Error fetching data', response);
    }

    const data = this.props.groupData;

    if (data && Array.isArray(data.clients)) {
      this.setState({
        targetKeys: data.clients
      });
      ok = ok && true;
    } else {
      this.setState({
        targetKeys: []
      });
      DetailedMessage.error('Error getting previous permissions', data);
      ok = false;
    }
    if (ok) {
      this.setState({ loading: false });
    }
  }

  render() {
    return this.state.data ? (
      <>
        <TableTransfer
          listStyle={{
            height: '100%'
          }}
          rowKey={record => record.id}
          dataSource={this.state.data}
          targetKeys={this.state.targetKeys}
          disabled={this.state.loading}
          showSearch={true}
          onChange={this.onChange}
          titles={['Not Allowed', 'Allowed']}
          filterOption={(inputValue, item) => {
            if (!item.id.toLowerCase) {
              return false;
            }
            if (!inputValue.toLowerCase) {
              return false;
            }
            return (
              item.id.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
              item.Nome.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            );
          }}
          style={{ width: '100%', height: '100%' }}
          leftColumns={tableColumns}
          rightColumns={tableColumns}
          locale={{
            itemUnit: 'client',
            itemsUnit: 'clients',
            notFoundContent: 'No data',
            searchPlaceholder: 'Search'
          }}
        />
        <div style={styles.actionBox}>
          <Button
            style={styles.button}
            type="primary"
            onClick={async () => {
              this.setState({ loading: true });
              // console.log('setting group allowed clients: ', {
              //   _id: this.props.groupData._id,
              //   clients: this.state.targetKeys
              // });
              const response = await putGroup({
                _id: this.props.groupData._id,
                clients: this.state.targetKeys
              });
              if (response && response.result === 'OK') {
                message.success('Successfully saved data');
              } else {
                DetailedMessage.error('Error saving data.', response);
              }
              this.setState({ loading: false });
            }}
          >
            Save
          </Button>
        </div>
      </>
    ) : (
      <Loading></Loading>
    );
  }
}

export default ManagePermissions;
