import React from 'react';
import { Button, Icon } from 'antd';
import { withTranslation } from 'react-i18next';
import { scrollToId } from '../../common/util';
import g from '../../styles/global';
import { ReactComponent as Img } from '../../img/undraw_delivery_address.svg';

const styles = {
  container: {
    with: '100%',
    height: `calc(100vh - ${g.global.baseline * 6}px)`,
    overflow: 'hidden',
    backgroundColor: '#e8e8e6',
    backgroundImage:
      'url("https://images.unsplash.com/photo-1458303210916-a83c4b8538e1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1765&q=80")',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center center',
    ...g.layout.flexCenter,
    ...g.layout.flexVertical,
    ...g.layout.alignCenter,
    color: '#fff',
    fontSize: g.global.baseline * 2,
    textShadow: '2px 2px 6px #444',
    fontWeight: 100
  },
  imageContainer: {
    maxHeight: '50%',
    minHeight: '30%'
  },
  image: { height: '100%' },
  content: { width: '50%', textAlign: 'center' },
  margin: {
    marginBottom: g.global.baseline * 2
  },
  button: {
    color: '#fff',
    fontWeight: 100,
    borderColor: '#fff',
    textShadow: '2px 2px 6px #444'
  }
};

class Banner extends React.PureComponent {
  render() {
    const t = this.props.t;
    return (
      <div style={styles.container}>
        {/* <div style={{ ...styles.imageContainer, ...styles.margin }}>
          <Img style={styles.image}></Img>
        </div> */}
        <div
          style={{
            ...styles.content,
            ...styles.margin,
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
            fontSize: '30px'
          }}
        >
          {t('pages.landing.hero.subtitle')}
        </div>
        {/* <Button
          ghost
          type="secondary"
          style={{ ...styles.button, ...styles.margin }}
          onClick={() => scrollToId('why')}
        >
          {t('pages.landing.hero.learn')}
        </Button>
        <Icon type="down" /> */}
      </div>
    );
  }
}
export default withTranslation()(Banner);
