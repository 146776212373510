import React from 'react';
import AppContext from '../../../context/AppContextBase';
import { Layout, Icon } from 'antd';
import { Helmet } from 'react-helmet-async';
import { Route, Redirect } from 'react-router-dom';

import Create from './Create';
import List from './List';

import globalStyles from '../../../styles/global';

const styles = {
  container: {
    height: 'calc( 100vh - ' + globalStyles.global.baseline * 6 + 'px)',
    width: '100%'
  }
};

class Groups extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <>
        <Helmet>
          <title>Users</title>
        </Helmet>
        <Layout style={styles.container}>
          <Route
            exact
            path={'/settings/users'}
            render={() => (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  ...globalStyles.layout.flexVertical,
                  ...globalStyles.layout.flexCenter
                }}
              >
                <Icon
                  style={{
                    fontSize: 580,
                    color: '#EAEDF4'
                  }}
                  type="fund"
                />
                <Redirect to="/settings/groups/list" />
              </div>
            )}
          />
          <Route path={'/settings/groups/list'} component={List} />
          <Route path={'/settings/groups/create'} component={Create} />
        </Layout>
      </>
    );
  }
}

export default Groups;
