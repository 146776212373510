import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, Icon } from 'antd';
import { withMedia } from 'react-media-query-hoc';
import { withTranslation } from 'react-i18next';
import { i18n } from '../i18n';
import AppContext from '../context/AppContextBase';
import { mrg, log } from '../common/util';
import g from '../styles/global';
import Profile from '../components/sections/Profile';

import LanguageSwitcher from '../components/i18n/LanguageSwitcher';

const { Sider } = Layout;

let styles = {
  link: {
    ...g.layout.flexHorizontal,
    ...g.layout.flexCenter,
    height: '100%',
    color: '#fff'
  },
  logo: {
    color: '#fff',
    fontSize: g.typography.size.base * 4,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    lineHeight: 1,
    paddingBottom: g.global.baseline / 4 // Adjust for icon height (fkn ant...)
  },
  logoContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: g.global.baseline * 2,
    paddingRight: g.global.baseline * 2
  },
  menuContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    ...g.layout.flexCenter,
    ...g.layout.flexHorizontal,
    ...g.layout.alignCenter,
    backgroundColor: g.colors.background.foodprintsGreenDark
  },
  menu: {
    height: g.global.baseline * 6,
    fontWeight: 100,
    textAlign: 'right',
    width: '100%',
    paddingLeft: g.global.baseline * 2,
    paddingRight: g.global.baseline * 2
  },
  menuItem: {
    verticalAlign: 'middle',
    color: '#fff',
    fontSize: g.typography.size.base * 1.5,
    fontWeight: 100,
    height: '100%',
    paddingBottom: g.global.baseline / 4, // Adjust for icon height (fkn ant...)
    margin: 0
  },
  menuItemNoLink: {
    height: '100%',
    ...g.layout.flexCenter,
    ...g.layout.flexHorizontal,
    ...g.layout.alignCenter
  },
  menuIconContainer: {
    minWidth: g.global.baseline * 5
  },
  menuIcon: {
    color: '#fff',
    fontSize: g.global.baseline * 3,
    fontWeight: 100,
    lineHeight: 1,
    svg: {
      height: '100%'
    }
  },
  mobileMenuSider: {
    zIndex: 10,
    minWidth: '100vh',
    position: 'fixed',
    top: 0,
    left: 0
  },
  mobileMenuContainer: {
    minHeight: '100vh',
    padding: '24vmin',
    ...g.layout.flexHorizontal,
    ...g.layout.flexCenter,
    backgroundColor: g.colors.background.foodprintsGreenDark
  },
  mobileMenu: {
    borderRight: 'unset',
    width: '100%',
    paddingLeft: g.global.baseline * 2,
    paddingRight: g.global.baseline * 2
  },
  mobileMenuToggle: {
    position: 'fixed',
    right: g.global.baseline * 2,
    top: g.global.baseline * 2,
    fontSize: g.typography.size.base * 2,
    transform: 'translateY(-50%)',
    zIndex: 20,
    ...g.layout.flexVertical,
    ...g.layout.flexCenter
  },
  mobileMenuHeader: {
    height: '100%',
    paddingRight: g.global.baseline * 2,
    ...g.layout.flexHorizontal,
    ...g.layout.flexCenter
  },
  mobileMenuItem: {
    fontSize: g.typography.size.base * 2,
    fontWeight: 100,
    minHeight: g.global.baseline * 6,
    ...g.layout.flexHorizontal,
    ...g.layout.flexStart
  },

  icon: {
    fontSize: g.typography.size.base * 2,
    alignSelf: 'center',
    fontWeight: 100,
    margin: 0
  },
  button: {
    fontSize: g.typography.size.base * 1.5,
    fontWeight: 100,
    backgroundColor: '#fff'
  }
};
class AppLogo extends React.Component {
  render() {
    const link = (
      <Link
        style={mrg([
          styles.logo,
          styles.link,
          { height: '100%', width: '100%' }
        ])}
        onClick={this.props.onClick}
        key="logo"
        to="/"
      >
        <span>Foodprints</span>
      </Link>
    );
    return (
      <>
        {this.props.media.mobile || this.props.media.tablet ? (
          <div
            key="logo"
            style={mrg([
              styles.logoContainer,
              this.props.style,
              {
                width: '250px',
                height: '100%',
                fontSize: 'unset'
              }
            ])}
          >
            {link}
          </div>
        ) : (
          <div
            key="logo"
            style={mrg([
              styles.logoContainer,
              this.props.style,
              {
                width: '250px',
                height: '100%',
                fontSize: 'unset',
                padding: '20px'
              }
            ])}
          >
            {link}
          </div>
        )}
      </>
    );
  }
}

AppLogo = withMedia(AppLogo);
export { AppLogo };

class AppMenu extends React.Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = { collapsed: true, showProfile: false };

    this.toggle = this.toggle.bind(this);
    this.menu = this.menu.bind(this);
    this.checkPermission = this.context.checkPermission;
  }

  menu(isMobile) {
    return (
      <>
        <AppLogo style={{ width: 'auto' }} />
        <Menu
          theme="dark"
          mode={isMobile ? 'inline' : 'horizontal'}
          style={isMobile ? styles.mobileMenu : styles.menu}
        >
          <Menu.Item
            style={isMobile ? styles.mobileMenuItem : styles.menuItem}
            key="example"
          >
            <Link style={styles.link} to="/foodprints">
              Survey Stats
            </Link>
          </Menu.Item>
          {/* {this.checkPermission('menu:settings') && (
            <Menu.Item
              style={
                isMobile
                  ? styles.mobileMenuItem
                  : mrg([
                      styles.menuItem,
                      styles.menuIconContainer,
                      { fontSize: 'unset', float: 'right' }
                    ])
              }
              key="menu5"
            >
              <Link style={styles.link} to="/settings">
                <Icon
                  style={{
                    fontSize: g.global.baseline * 1.5
                  }}
                  type="setting"
                />
              </Link>
            </Menu.Item>
          )} */}
          {/* <Menu.Item
            onClick={() => {
              this.setState((prevState, props) => ({
                ...prevState,
                showProfile: !prevState.showProfile
              }));
            }}
            style={
              isMobile
                ? styles.mobileMenuItem
                : mrg([
                    styles.menuItem,
                    styles.menuIconContainer,
                    { fontSize: 'unset', float: 'right' }
                  ])
            }
            key="profile"
          >
            <Profile
              visible={this.state.showProfile}
              handleClick={false}
              user={this.context.state.userData}
              drawerWidth={isMobile ? '100vw' : '50vw'}
            />
          </Menu.Item> */}
          <Menu.Item
            style={
              isMobile
                ? styles.mobileMenuItem
                : mrg([
                    styles.menuItem,
                    styles.menuIconContainer,
                    {
                      fontSize: 'unset',
                      float: 'right'
                    }
                  ])
            }
            key="language"
          >
            <LanguageSwitcher language={i18n.language}> </LanguageSwitcher>
          </Menu.Item>
        </Menu>
      </>
    );
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    return (
      <>
        {this.props.media.mobile || this.props.media.tablet ? (
          <Layout>
            <Icon
              className="trigger"
              style={styles.mobileMenuToggle}
              type={this.state.collapsed ? 'menu' : 'close'}
              onClick={this.toggle}
            />
            <Sider
              trigger={null}
              reverseArrow={true}
              collapsible={true}
              collapsedWidth="0"
              collapsed={this.state.collapsed}
              width={'100vw'}
              onCollapse={(collapsed, type) => {
                log(collapsed, type);
              }}
              style={styles.mobileMenuSider}
            >
              <div style={styles.mobileMenuContainer}>{this.menu(true)}</div>
            </Sider>
          </Layout>
        ) : (
          <div key="menu" style={styles.menuContainer}>
            {this.menu(false)}
          </div>
        )}
      </>
    );
  }
}

AppMenu.contextType = AppContext;
AppMenu = withTranslation()(withMedia(AppMenu));

export { AppMenu };
