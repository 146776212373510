import moment from 'moment';

export const debug = false;
//eslint-disable-next-line
export const log = debug ? console.log.bind(window.console) : () => {};

export const get = (p, o) => {
  if (typeof p === 'string') {
    p = p.split('.');
  }
  return p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
};

export const mrg = (first, ...rest) => {
  let result;
  if (rest && rest.lentgh) {
    result = [first, ...rest];
  } else {
    if (Array.isArray(first)) {
      result = first;
    } else {
      result = [first];
    }
  }

  return result.reduce(
    (previous, current) => Object.assign(previous, current),
    {}
  );
};

export const validateHTTPMethod = (method, validMethods) => {
  if (!validMethods.includes(method)) {
    throw new Error(
      'Invalid method. Method should be one of: ' +
        validMethods.reduce((prev, curr) => prev + ', ' + curr, '')
    );
  }
  return method;
};

export const tryParseDate = (string, options = {}) => {
  const formats = [
    'M-D-YYYY',
    'MM-DD-YYYY',
    'DD-MM-YYYY',
    'D/MM/YYYY',
    'DD/MM/YY',
    'M-D-YY',
    'DD-MM-YY',
    'D/MM/YY',
    'DD/MM/YY'
  ];
  let result;
  for (let format of formats) {
    result = moment(string, format);
    if (result.isValid()) {
      return result;
    }
  }
  if (options.nullable) {
    return null;
  }
  return moment('invalid date');
};

export const handleRequest = (request, erroMessage) => {
  if (!request || request.result !== 'OK' || !request.data) {
    throw request;
  }
  return request;
};


export const scrollToId = id => {
  const element = document.getElementById(id);

  if (element) {
    console.log('scrolling')
    window.scroll({
      top: element.getBoundingClientRect().top,
      behavior: 'smooth'
    });
  }
}