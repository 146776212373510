import React from 'react';
import AppContext from '../../../context/AppContextBase';
import { Helmet } from 'react-helmet-async';
import { Layout, Tooltip, Button, message } from 'antd';
import {
  AutoSizer,
  Column,
  Table,
  SortIndicator,
  SortDirection
} from 'react-virtualized';
import sort from 'fast-sort';
import op from 'object-path';

import globalStyles from '../../../styles/global';
import { mrg } from '../../../common/util';
import { getGroups, putGroup } from '../../../network/users';

import ManagePermissions from './ManagePermissions';
import ClientAccessPermissions from './permissions/ClientPermissions';

import EditableField from '../../../components/fields/EditableField';
import DetailedMessage from '../../../components/util/DetailedMessage';
import NoData from '../../../components/util/NoData';
import Loading from '../../../components/util/Loading';

const { Content } = Layout;

const styles = {
  fullWidth: {
    width: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  full: {
    width: '100%',
    height: '100%'
  },
  content: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll'
  },
  action: {
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    width: '100%',
    marginBottom: globalStyles.global.baseline,
    fontSize: globalStyles.global.baseline * 1.2
  },
  info: {
    body: {
      ...globalStyles.layout.flexVertical,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignCenter
    },
    item: {
      marginBottom: globalStyles.global.baseline
    },
    icon: { marginRight: globalStyles.global.baseline / 2 }
  },
  tag: {
    marginBottom: globalStyles.global.baseline * 0.5,
    fontSize: globalStyles.global.baseline * 0.8,
    padding: '0 ' + globalStyles.global.baseline * 0.5 + 'px'
  },

  pageHeaderContainer: {
    width: '100%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexBetween,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerLeft: {
    width: '40%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContainerRight: {
    width: '60%',
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexEnd,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderTitle: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#666666',
    textTransform: 'uppercase',
    paddingTop: globalStyles.global.baseline,
    paddingBottom: globalStyles.global.baseline,
    paddingLeft: globalStyles.global.baseline,
    paddingRight: globalStyles.global.baseline / 2,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderBox: {
    paddingBottom: globalStyles.global.baseline,
    paddingTop: globalStyles.global.baseline,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  pageHeaderContent: {
    fontSize: globalStyles.global.baseline * 2,
    fontWeight: 100,
    color: '#444444',
    textTransform: 'none',
    marginLeft: globalStyles.global.baseline
  },
  menuIcon: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + globalStyles.global.baseline + 'px'
  },
  permissionData: {
    fontWeight: 100,
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexStart,
    ...globalStyles.layout.alignCenter
  },
  table: {
    body: { height: '100%' },
    evenRow: {
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0'
    },
    oddRow: {
      borderRight: '1px solid #e0e0e0',
      backgroundColor: '#fafafa',
      borderBottom: '1px solid #e0e0e0'
    },
    descendantRow: {
      borderRight: '1px solid #e0e0e0',
      borderBottom: '1px solid #e0e0e0',
      backgroundColor: '#e0f0ff'
    },
    headerRow: {
      textTransform: 'none',
      backgroundColor: '#c0c0ca',
      fontSize: globalStyles.global.baseline,
      fontWeight: 500,
      height: globalStyles.global.baseline * 4
    },
    column: {
      fontWeight: 100,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      borderRight: '1px solid #e0e0e0'
    },
    overflowColumn: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    checkboxLabel: {
      marginLeft: '5rem'
    },
    noRows: {
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1em',
      color: '#bdbdbd'
    }
  }
};

const baseColumnWidth = globalStyles.global.baseline * 4;

const labelMap = {
  id: 'ID',
  name: 'Name',
  'permissions.department': 'Dept',
  'permissions.scope': 'Scope'
};
const mapHeaderLabel = label => {
  if (labelMap[label]) {
    return labelMap[label];
  }
  return label;
};

class List extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = { modalGroupId: null };

    this.tableRef = null;

    this.columnHeader = this.columnHeader.bind(this);
    this.sort = this.sort.bind(this);
    this.sortList = this.sortList.bind(this);
    this.search = this.search.bind(this);
    this.rowStyle = this.rowStyle.bind(this);
    this.rowHeight = this.rowHeight.bind(this);
    this.onSaveSuccess = this.onSaveSuccess.bind(this);
    this.onSaveError = this.onSaveError.bind(this);
    this.showPermissions = this.showPermissions.bind(this);
  }

  async componentDidMount() {
    if (!this.state.sortedList) {
      await this.refresh();
    }
    await this.sort({
      sortBy: this.state.sortBy,
      sortDirection: this.state.sortDirection
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.showDescendants !== prevState.showDescendants) {
      await this.refresh();
      await this.sort({
        sortBy: this.state.sortBy,
        sortDirection: this.state.sortDirection
      });
      if (this.tableRef) {
        await this.tableRef.recomputeRowHeights();
      }
    }
  }

  onSaveSuccess() {
    return message.success('Values saved successfully');
  }
  onSaveError(err) {
    return DetailedMessage.error('Error saving values', err);
  }

  showPermissions() {
    const groups = this.state.sortedList.filter(
      group => group._id === this.state.modalGroupId
    );
    if (Array.isArray(groups) && groups.length === 1) {
      const group = groups[0];
      const dept = group.department
        ? group.department.toLowerCase()
        : 'unknown';
      switch (dept) {
        case 'id':
          return (
            <ClientAccessPermissions
              groupData={group}
            ></ClientAccessPermissions>
          );
        default:
          return <div>Unknown group type</div>;
      }
    } else {
      return <div>Unknown group type</div>;
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Users - List</title>
          {/* antd overrides */}
          <style type="text/css">{`.ant-table-thead > tr > th {
              padding: ${globalStyles.global.baseline}px;
            }
            tr.ant-table-row td {
              padding: ${globalStyles.global.baseline}px;
              line-height: 1;
            }
          `}</style>
        </Helmet>
        <Content
          style={mrg([
            styles.fullWidth,
            globalStyles.layout.flexVertical,
            globalStyles.layout.flexStart,
            globalStyles.layout.alignStart,
            styles.content
          ])}
        >
          <div
            style={{
              ...styles.pageHeaderContainer,
              height: globalStyles.global.baseline * 4
            }}
          >
            <span style={styles.pageHeaderTitle}>User Groups</span>
          </div>
          <div
            style={{
              height: `calc( 100% - ${globalStyles.global.baseline * 10}px)`,
              width: '100%'
            }}
          >
            {this.state.sortedList ? (
              <AutoSizer>
                {({ height, width }) => (
                  <Table
                    ref={ref => (this.tableRef = ref)}
                    style={styles.table.body}
                    height={height}
                    rowHeight={this.rowHeight}
                    rowStyle={({ index }) => this.rowStyle({ index })}
                    noRowsRenderer={NoData}
                    rowGetter={({ index }) => {
                      return this.state.sortedList[index];
                    }}
                    rowCount={this.state.sortedList.length}
                    sort={this.sort}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    width={width}
                  >
                    <Column
                      width={baseColumnWidth}
                      flexGrow={2}
                      dataKey="name"
                      disableSort={false}
                      style={styles.table.column}
                      headerRenderer={this.columnHeader}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={2}
                      dataKey="email"
                      headerRenderer={this.columnHeader}
                      style={mrg([
                        styles.table.column,
                        styles.table.overflowColumn
                      ])}
                      cellRenderer={({ cellData, rowData }) => {
                        return (
                          <Tooltip
                            key={cellData}
                            placement="top"
                            title={cellData}
                          >
                            {cellData}
                          </Tooltip>
                        );
                      }}
                    />

                    <Column
                      width={baseColumnWidth}
                      flexGrow={1}
                      dataKey="department"
                      disableSort={false}
                      style={styles.table.column}
                      headerRenderer={this.columnHeader}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={4}
                      dataKey="notes"
                      disableSort={true}
                      style={styles.table.column}
                      cellRenderer={({ cellData, rowData }) => {
                        return (
                          <EditableField
                            type={EditableField.Types.textarea}
                            minWidthBefore={0}
                            innerStyle={{ marginBottom: 0 }}
                            span={24}
                            autosize={{ minRows: 2, maxRows: 2 }}
                            key={rowData._id}
                            editable
                            data={cellData}
                            title={'Notes'}
                            value={cellData}
                            onSave={async value =>
                              await putGroup({
                                _id: rowData._id,
                                notes: value
                              })
                            }
                            onSaveSuccess={async result =>
                              await this.onSaveSuccess(result)
                            }
                            onSaveError={async err =>
                              await this.onSaveError(err)
                            }
                          ></EditableField>
                        );
                      }}
                      headerRenderer={this.columnHeader}
                    />
                    <Column
                      width={baseColumnWidth}
                      flexGrow={1}
                      dataKey="_id"
                      disableSort={false}
                      style={styles.table.column}
                      cellRenderer={({ cellData, rowData }) => (
                        <div style={styles.permissionData}>
                          <span>
                            {rowData.clients ? rowData.clients.length : 0}{' '}
                            allowed clients
                          </span>
                          <Button
                            type="link"
                            onClick={() =>
                              this.setState({ modalGroupId: cellData })
                            }
                          >
                            Manage
                          </Button>
                        </div>
                      )}
                      headerRenderer={() => 'Permissions'}
                    />
                  </Table>
                )}
              </AutoSizer>
            ) : (
              <Loading></Loading>
            )}
          </div>
        </Content>
        {this.state.modalGroupId && (
          <ManagePermissions
            handleGoBack={async () => {
              this.setState({ modalGroupId: false });
              return await this.refresh();
            }}
          >
            {this.showPermissions()}
          </ManagePermissions>
        )}
      </>
    );
  }
  columnHeader({ dataKey, sortBy, sortDirection }) {
    return (
      <div>
        {
          <span
            onClick={() =>
              this.setState((state, props) => ({
                sortBy: dataKey
              }))
            }
          >
            {mapHeaderLabel(dataKey).toUpperCase()}
          </span>
        }
        {sortBy === dataKey && (
          <SortIndicator
            onClick={() =>
              this.setState((state, props) => ({
                sortDirection: state.sortDirection === 'ASC' ? 'DESC' : 'ASC'
              }))
            }
            sortDirection={sortDirection}
          />
        )}
      </div>
    );
  }

  async refresh() {
    this.setState({ loading: true });
    let groups = await getGroups();

    if (groups && groups.result === 'OK' && groups.data.length > 0) {
      this.setState({
        sortedList: groups.data
      });
    } else if (groups && groups.result === 'OK') {
      this.setState({
        sortedList: []
      });
    } else {
      DetailedMessage.error('Error loading Data', groups);
      this.setState({
        sortedList: []
      });
    }
    this.setState({ loading: false });
  }

  rowStyle({ index }) {
    if (index === -1) {
      return styles.table.headerRow;
    }
    if (index % 2 === 0) {
      return styles.table.evenRow;
    } else {
      return styles.table.oddRow;
    }
  }

  rowHeight({ index }) {
    return globalStyles.global.baseline * 9;
  }

  async search() {
    this.setState({ searching: true });
    if (!this.state.sortedList || this.state.searching || !this.state.search) {
      this.setState({ searching: false, sortedList: this.clientCache });
      return;
    }

    const clients = this.clientCache.filter(
      kase => JSON.stringify(kase).indexOf(this.state.search) !== -1
    );
    this.setState({ sortedList: clients, searching: false });
    if (this.tableRef) {
      await this.tableRef.recomputeRowHeights();
    }
  }

  async sort({ sortBy, sortDirection }) {
    const sortedClients = this.sortList({
      list: this.state.sortedList,
      sortBy,
      sortDirection
    });

    this.setState({ sortBy, sortDirection, sortedClients });
    if (this.tableRef) {
      await this.tableRef.recomputeRowHeights();
    }
  }

  sortList({ list, sortBy, sortDirection }) {
    const toSort = sort(list);

    if (sortBy === 'unread') {
      switch (sortDirection) {
        case SortDirection.ASC:
          return toSort.asc(
            client => client.unreadAri + client.unreadCrm + client.unreadAudit
          );
        case SortDirection.DESC:
          return toSort.desc(
            client => client.unreadAri + client.unreadCrm + client.unreadAudit
          );
        default:
          return list;
      }
    }

    let sb = sortBy;

    if (sortBy && sortBy.includes('.')) {
      sb = data => op.get(data, sortBy);
    }

    switch (sortDirection) {
      case SortDirection.ASC:
        return toSort.asc(sb);
      case SortDirection.DESC:
        return toSort.desc(sb);
      default:
        return list;
    }
  }
}

export default List;
