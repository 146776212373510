//const address = 'http://localhost:3000/';
//import { address } from '../common/variables';

var address;
if (process.env.NODE_ENV === 'production') {
  address = 'https://urbanfoodprints.com/api';
} else {
  address = 'http://localhost:3000';
}
//changes
//eslint-disable-next-line
import { handleResponse, authenticate } from './common';

export async function getFood() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(address + '/food', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
      //  jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  let body = await response.json();
  return body;
  //return await handleResponse(response);
}

export async function getAllSurveys() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(address + '/survey', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    }
  });
  let body = await response.json();
  return body;
  //return await handleResponse(response);
}
export async function sendSurvey(survey) {
  //Post one survey

  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(address + '/survey', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo.signInUserSession.accessToken.jwtToken
    },
    body: JSON.stringify(survey)
  });

  let body = await response.json();
  return body;
  //return await handleResponse(response);
}
