import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppContextProvider } from './context/AppContext';
import { MediaQueryProvider } from 'react-media-query-hoc';
import { HelmetProvider } from 'react-helmet-async';

/* default media queries: 
  mobile: 'screen and (max-width: 623px)',
  tablet: 'screen and (min-width: 624px) and (max-width: 1020px)',
  desktop: 'screen and (min-width: 1021px) and (max-width: 1440px)',
  largeDesktop: 'screen and (min-width: 1441px)'
*/

ReactDOM.render(
  <HelmetProvider>
    <MediaQueryProvider>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </MediaQueryProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
