import React from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../context/AppContextBase';
import { Helmet } from 'react-helmet-async';
import {
  Layout,
  Table,
  Divider,
  Tag,
  Row,
  Col,
  message,
  Select,
  Button,
  Icon
} from 'antd';

import { mrg } from '../../common/util';
import globalStyles from '../../styles/global';
//import Menu from '../components/Menu';
import graphLegend from './img/final_graph_foodlegend.svg';
import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';

import { AsyncParser, parse } from 'json2csv';
import { getAllSurveys } from '../../network/foodAndSurvey';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  Label
} from 'recharts';
const { Option } = Select;
const survey_question_dicionary = {
  '1_1_gender': 'What is your gender?',
  '2_1_age': 'What is your age?',
  '3A_1_country': 'Where do you live? (Country)',
  '3B_1_region': 'Where do you live? (Region)',
  '4_1_exercise': 'How often do you exercise?',
  '4_1_education':
    'What is the highest level of education you have completed (or are currently completing)?',
  '5_1_groupMember': 'Are you a member of any of these groups?',
  '6A1_group1':
    '6.A - On a typical week, how often is each of the following meal options used in your household? \n 6A Cook a meal',
  '6B_1_group1': '6B - Buy a ready to eat meal',
  '6C_1_group1': '6C - Eat at a restaurant',
  '7_2_where_to_buy': 'Where do you mostly buy your food?',
  '8A_2_group':
    '8. When buying food, how important are the following product characteristics to you? - \n8.A Brand',

  '8B_2_group': '8.B Convenience (pre-cut, pre-washed,easy to prepare)',
  '8C_2_group': '8.C Expiry date (longest validity)',
  '8D_2_group': '8.D Grown in your country',
  '8E_2_group': '8.E Grown in your region / city',
  '8F_2_group': '8.F Looks fresh',
  '8G_2_group': '8.G Nutritional value',
  '8H_2_group': '8.H Organic label',
  '8I_2_group': '8.I Packaging (environmentally-friendly)',
  '8J_2_group': '8.J Price',

  '9.1_2_importedOrNational':
    'Which one of these products would you buy? - Imported($1) or national?($1)',
  '9.2_2_importedOrNational':
    'Which one of these products would you buy? - Imported($1) or national?($1.5)',
  '10.1_2_nonOrganicOrOrganic':
    'Which one of these products would you buy? - Non-organic($1) or organic?($1)',

  '10.2_2_nonOrganicOrOrganic':
    'Which one of these products would you buy? - Non-organic($1) or organic?($1.5)',
  '11_2_heard_of_hidroponics':
    'Which one of these products would you buy? - Non-organic($1) or organic?($1)',
  '12_2_buy_hidroponics':
    'Which one of these products would you buy? - Non-organic($1) or organic?($1)',

  '12.1_1_importedOrNational':
    '12.1. Imported or national? - Imported($1) or national?($1)',
  '12.1_2_importedOrNational':
    '12.1. Imported or national? - Imported($1) or national?($1.5)',
  '12.2_1_nonOrganicOrOrganic':
    '12.2. Non-organic or organic? - Non-organic($1) or organic?($1)',
  '12.2_2_nonOrganicOrOrganic':
    ' 12.2. Non-organic or organic? - Non-organic($1) or organic?($1.5)',
  '13.1_1_RegularOrLowCarbon':
    'Which one of these products would you buy? - Regular $ 1.00 Or Low-Carbon $ 1.00 ',
  '13.1_2_RegularOrLowCarbon':
    'Which one of these products would you buy?-  Regular $ 1.00 Or Low-Carbon $ 1.50 ',

  breakfast_page_plates: 'Breakfast Plate',
  lunch_page3: 'Lunch Plate',
  dinner_page3: 'Dinner Plate',
  snacks_page3: 'Snacks Plate'
};
const { Content } = Layout;

const styles = {
  header: {
    height: globalStyles.global.baseline * 4,
    backgroundColor: globalStyles.colors.background.pink,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter
  },
  link: {
    textAlign: 'center',
    fontSize: 36,
    fontWeight: 100,
    borderBottom: '2px solid ' + globalStyles.colors.background.pink
  },
  paragraph: {
    textAlign: 'center',
    fontSize: 96,
    fontWeight: 700,
    marginBottom: globalStyles.global.baseline
  },
  note: {
    textAlign: 'center',
    fontSize: 48,
    fontWeight: 100,
    marginBottom: globalStyles.global.baseline
  }
};

// const surveyList = [
//   {
//     key: '1',
//     date: '28-12-2018', //moment(value).format('YYYY-MM-DD HH:mm'),
//     number_of_plates: 3,
//     country: 'Portugal',
//     age: ['24_36'],
//     answers: 1
//   }
// ];

// const data = [
//   {
//     key: '1',
//     // name: 'John Brown',
//     number_of_answers: 10,
//     date: moment(Date.now()).format('YYYY-MM-DD HH:mm')
//     //tags: ['nice', 'developer'],
//     //action:
//   },
//   {
//     key: '2',
//     // name: 'John Brown',
//     number_of_answers: 10,
//     date: moment(Date.now()).format('YYYY-MM-DD HH:mm')
//     //tags: ['nice', 'developer']
//   }
// ];

class SurveyStatsScreen extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {
      data: null,
      number_of_surveys: 0,
      calculations: [],
      countStats: [],
      foodGroupsAverage: {},
      jsonToDownload: null
    };
  }

  async componentDidMount() {
    await this.getSurveysAndComputeResults();
  }

  async getSurveysAndComputeResults(filter) {
    //arg1 - active filter {group:"value"}
    console.log('filtering for ', filter);

    let surveyRequest = await getAllSurveys();

    let jsonToDownload = JSON.parse(JSON.stringify(surveyRequest)); //cleaning json to export/download

    jsonToDownload = jsonToDownload.map(survey => {
      survey.answers = survey.answers.filter(
        answer => answer.answer !== 'plates'
      );
      survey.answers.forEach(answer => {
        delete answer._id;
        delete answer.type;
        delete answer.groupAnswer;
        delete answer.othersValue;
        delete answer.plates;
      });
      delete survey.createdAt;
      delete survey.updatedAt;
      delete survey._id;
      delete survey.__v;

      return survey;
    });

    console.log('jsonToDownload', jsonToDownload);

    if (filter && filter.group && filter.group !== 'ALL') {
      surveyRequest = surveyRequest.filter(survey => {
        let belongsToFilter = survey.answers.some(
          answer => answer.answer === filter.group
          // console.log(
          //   'answer.answer: ',
          //   answer.answer,
          //   'filter.value: ',
          //   filter.value
          // );

          //iterate all answers
          // return Object.keys(answer).some(() => {
          //   //iterate on keys of the answer
          //   return answer.answer === filter.value;
          // });
        );
        console.log('belongsToFilter', belongsToFilter);
        return belongsToFilter;
      });
    }
    console.log('surveyRequest after filter:', surveyRequest);

    let processedSurveyData = surveyRequest.map(eachSurvey => {
      return {
        date: eachSurvey.date,
        number_of_answers: eachSurvey.answers.length,
        number_of_plates: eachSurvey.answers.filter(
          answer => answer.type === 'plates'
        ).length,
        key: eachSurvey._id
      };
    });

    const average = arr => {
      if (arr) {
        return arr.reduce((p, c) => p + c, 0) / arr.length;
      } else {
        return 0;
      }
    };

    let co2Array = {};
    let calArray = {};
    surveyRequest.forEach(survey => {
      if (survey.foodGroups) {
        Object.keys(survey.foodGroups).forEach(key => {
          if (co2Array[key]) {
            co2Array[key].push(survey.foodGroups[key].co2);
          } else {
            co2Array[key] = [survey.foodGroups[key].co2];
          }
        });
        Object.keys(survey.foodGroups).forEach(key => {
          if (calArray[key]) {
            calArray[key].push(survey.foodGroups[key].calories);
          } else {
            calArray[key] = [survey.foodGroups[key].calories];
          }
        });
      }
    });

    let foodGroupsAverageCO2 = {
      sugar_fat: parseFloat(average(co2Array.sugar_fat)).toFixed(2),
      grain: parseFloat(average(co2Array.grain)).toFixed(2),
      dairy_eggs: parseFloat(average(co2Array.dairy_eggs)).toFixed(2),
      other: parseFloat(average(co2Array.other)).toFixed(2),
      produce: parseFloat(average(co2Array.produce)).toFixed(2),
      meat: parseFloat(average(co2Array.meat)).toFixed(2)
    };
    let foodGroupsAverageCalories = {
      sugar_fat: parseFloat(average(calArray.sugar_fat)).toFixed(2),
      grain: parseFloat(average(calArray.grain)).toFixed(2),
      dairy_eggs: parseFloat(average(calArray.dairy_eggs)).toFixed(2),
      other: parseFloat(average(calArray.other)).toFixed(2),
      produce: parseFloat(average(calArray.produce)).toFixed(2),
      meat: parseFloat(average(calArray.meat)).toFixed(2)
    };

    console.log('co2Array:', co2Array);
    console.log('calArray:', calArray);
    console.log('foodGroupsAverageCO2', foodGroupsAverageCO2);
    console.log('foodGroupsAverageCalories', foodGroupsAverageCalories);
    this.setState({ foodGroupsAverageCO2, foodGroupsAverageCalories });
    //calculate stats
    let countStats = {};

    surveyRequest.forEach(survey => {
      let surveyAnswers = survey.answers;
      //console.log('surveyAnswers', surveyAnswers);

      surveyAnswers.forEach(answer => {
        let question = answer.survey_question;
        let questionType = answer.type;

        if (!countStats[question]) {
          countStats[question] = {
            answersAccounting: {},
            totalAnswers: 0,
            type: null,
            groupAnswer: {}
          };
          // countStats[question].description = answer.description;
        }

        if (questionType) {
          if (questionType !== 'plates') {
            //plates are not accounted
            countStats[question].type = 'single_answer'; //remove?
          }
          countStats[question].answersAccounting[answer.answer]
            ? (countStats[question].answersAccounting[answer.answer] += 1)
            : (countStats[question].answersAccounting[answer.answer] = 1);

          countStats[question].totalAnswers += 1;
          // countStats[question].description =
          //   survey_question_dicionary[question];
        }
        //no group or single answer distinction now - all treated as single

        // if (questionType === 'group_answer') {
        //   //groupAnswer:["4","2","4","3"]
        //   countStats[question].type = 'group_answer';
        //   answer.groupAnswer.forEach((eachGroupAnswer, index) => {
        //     if (!countStats[question].answersAccounting[index]) {
        //       countStats[question].answersAccounting[index] = {};
        //     }
        //     if (
        //       !countStats[question].answersAccounting[index][eachGroupAnswer]
        //     ) {
        //       countStats[question].answersAccounting[index][
        //         eachGroupAnswer
        //       ] = 1;
        //     } else {
        //       countStats[question].answersAccounting[index][
        //         eachGroupAnswer
        //       ] += 1;
        //     }
        //   });
        // }
        if (questionType === 'group_answer') {
          countStats[question].type = 'group_answer';
        }

        if (questionType === 'plates') {
          //MISSING!
          //countStats[question].type = 'plates'; //remove?
        }
      });
    });

    //console.log('countStats', countStats);
    this.setState({
      data: processedSurveyData,
      number_of_surveys: processedSurveyData.length,
      countStats: countStats,
      jsonToDownload
    });
  }
  getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render_single_answer_graph_row(question_key) {
    let answersDescription = survey_question_dicionary[question_key];

    let totalAnswers;
    let answersKeyArray = Object.keys(
      this.state.countStats[question_key].answersAccounting
    );
    let graphData = {
      labels: answersKeyArray.map(key => key.toUpperCase()),

      datasets: [
        {
          data: answersKeyArray.map(
            key => this.state.countStats[question_key].answersAccounting[key]
          ),
          backgroundColor: answersKeyArray.map(() => this.getRandomColor())
          // hoverBackgroundColor: '#26C485'
        }
      ]
    };
    if (this.state.countStats[question_key].type !== 'plates')
      return (
        <div
          style={{
            height: 'auto',
            //borderStyle: 'solid',

            padding: '20px',
            border: '1px solid #ccc',
            backgroundColor:
              this.state.countStats[question_key].type === 'group_answer'
                ? '#ddd'
                : null,
            borderRadius: '4px',
            margin: '5px'
          }}
        >
          <Row type="flex" align="middle" justify="center">
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 7 }}>
              <div style={{ fontSize: '20px' }}>{answersDescription}</div>
            </Col>
          </Row>
          <Row type="flex" align="middle" justify="center">
            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 9 }}>
              <div style={{ fontSize: '16px' }}>
                Total Answers:{' '}
                {this.state.countStats[question_key].totalAnswers}
                <br />
                {/* EX:
              Total Answers: 4
              Answers:
              "Imported": 75.00%(3)
              "National": 25.00%(1) */}
                {'Answers: '}
                {answersKeyArray.map(key => (
                  <div>
                    {'"' +
                      key +
                      '": ' +
                      ((
                        (this.state.countStats[question_key].answersAccounting[
                          key
                        ] /
                          this.state.countStats[question_key].totalAnswers) *
                        100
                      ).toFixed(2) +
                        '%') +
                      '(' +
                      this.state.countStats[question_key].answersAccounting[
                        key
                      ] +
                      ') '}
                  </div>
                ))}
              </div>
            </Col>

            <Col xs={{ span: 20, offset: 2 }} lg={{ span: 7 }}>
              <div>
                <Doughnut data={graphData} />
              </div>
            </Col>
          </Row>
        </div>
      );
  }

  // render_group_answer_graph_row(question_key) {
  //   console.log('render_group_answer_graph_row', question_key);
  //   let answersKeyArray_ = Object.keys(
  //     this.state.countStats[question_key].answersAccounting
  //   );
  //   return answersKeyArray_.map((subQuestionNumber, index) => {
  //     let subQuestion = this.state.countStats[question_key].answersAccounting[
  //       subQuestionNumber
  //     ];

  //     console.log('subQuestion', subQuestion);

  //     let graphData = {
  //       labels: Object.keys(subQuestion).map(value => value + 'º Option'),
  //       datasets: [
  //         {
  //           label: index + 1 + 'º answer',
  //           data: Object.values(subQuestion),
  //           backgroundColor: Object.keys(subQuestion).map(() =>
  //             this.getRandomColor()
  //           )
  //           //hoverBackgroundColor: '#26C485'
  //         }
  //       ]
  //     };
  //     console.log('graphData', graphData);

  //     return (
  //       <Row type="flex" align="middle" justify="center">
  //         <Col xs={{ span: 20, offset: 2 }} lg={{ span: 11 }}>
  //           <div style={{ fontSize: '20px' }}>Question {question_key}</div>
  //         </Col>
  //         <Col xs={{ span: 20, offset: 2 }} lg={{ span: 11 }}>
  //           <div>
  //             <Bar
  //               data={graphData}
  //               width={200}
  //               height={100}
  //               options={{ maintainAspectRatio: false }}
  //             />
  //           </div>
  //         </Col>
  //       </Row>
  //     );
  //   });
  // }
  render_graph_row(question_key) {
    //return this.render_single_answer_graph_row(question_key);

    //no group or single answer distinction now
    if (this.state.countStats[question_key].type) {
      return this.render_single_answer_graph_row(question_key);
    }
    //else {
    //   return <div>Can't understand the data.</div>;
    // }
  }

  getJSON() {
    var data = new Blob([JSON.stringify(this.state.jsonToDownload, null, 2)], {
      type: 'text/json'
    });
    var csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement('a');
    document.body.appendChild(tempLink);
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'surveys.json');
    tempLink.target = '_self';
    tempLink.click();
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Survey List</title>
        </Helmet>
        <Layout
          style={{
            margin: 0,
            padding: 0,
            width: '100%',
            height: 'auto',
            backgroundColor: '#fff'
          }}
        >
          <Content
            // style={mrg([
            //   globalStyles.layout.flexVertical
            //   //globalStyles.layout.flexCenter
            // ])}
            style={{ padding: '20px', height: 'auto' }}
          >
            <>
              <p style={styles.note}>
                Number of surveys: {this.state.number_of_surveys}
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ color: '#000' }}>
                  <div>Select a group</div>
                  <Select
                    style={{ width: '200px' }}
                    defaultValue="ALL"
                    //style={{ width: 120 }}
                    value={this.state.activatedFilter}
                    onChange={async value => {
                      this.setState({ activatedFilter: value });
                      this.getSurveysAndComputeResults({ group: value });
                    }}

                    // onChange={this.handle_Question_5_change}
                  >
                    <Option value="ALL">ALL</Option>
                    <Option value="MIT">Only MIT</Option>
                    <Option value="KACST"> Only KACST</Option>
                    <Option value="HOK">HOK</Option>
                    <Option value="IST Lisboa">IST Lisboa</Option>
                    <Option value="Elementa/Integral">Elementa/Integral</Option>
                    <Option value="ARUP">ARUP</Option>
                    <Option value="KPF">KPF</Option>
                    <Option value="Testing Team">Testing Team</Option>

                    {/* <Option value="SOLEMMA SYMPOSIUM">
                    Only SOLEMMA SYMPOSIUM
                  </Option> */}
                  </Select>
                </div>

                <Button
                  onClick={() => this.getJSON()}
                  type="primary"
                  shape="round"
                  icon="download"
                  size={20}
                  style={{ background: '#46645b' }}
                >
                  Get database JSON
                </Button>
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    padding: '50px',
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                  }}
                >
                  <div>
                    <div style={{ fontSize: 22 }}>Calories</div>
                    <BarChart
                      width={500}
                      height={300}
                      data={[this.state.foodGroupsAverageCalories]}
                      stackOffset="sign"
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Label
                        value="KCalories/Year"
                        offset={0}
                        position="insideBottom"
                      />
                      {/* <Legend /> */}
                      <ReferenceLine y={0} stroke="#000" />
                      <Bar dataKey="meat" fill="#8A0000" stackId="stack" />
                      <Bar
                        dataKey="dairy_eggs"
                        fill="#F97432"
                        stackId="stack"
                      />
                      <Bar dataKey="grain" fill="#FFCC01" stackId="stack" />
                      <Bar dataKey="produce" fill="#25523B" stackId="stack" />
                      <Bar dataKey="sugar_fat" fill="#2C43B8" stackId="stack" />
                      <Bar dataKey="other" fill="#502989" stackId="stack" />
                    </BarChart>{' '}
                  </div>
                  <div>
                    <div style={{ fontSize: 22 }}>CO2:</div>
                    <BarChart
                      width={500}
                      height={300}
                      data={[this.state.foodGroupsAverageCO2]}
                      stackOffset="sign"
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Label
                        value="CO2 - tCO2eq/year"
                        offset={0}
                        position="insideBottom"
                      />
                      {/* <Legend /> */}
                      <ReferenceLine y={0} stroke="#000" />
                      <Bar dataKey="meat" fill="#8A0000" stackId="stack" />
                      <Bar
                        dataKey="dairy_eggs"
                        fill="#F97432"
                        stackId="stack"
                      />
                      <Bar dataKey="grain" fill="#FFCC01" stackId="stack" />
                      <Bar dataKey="produce" fill="#25523B" stackId="stack" />
                      <Bar dataKey="sugar_fat" fill="#2C43B8" stackId="stack" />
                      <Bar dataKey="other" fill="#502989" stackId="stack" />
                    </BarChart>
                  </div>
                  <div
                    // clear button
                    style={{
                      display: 'flex',
                      backgroundImage: 'url(' + graphLegend + ')',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '50% 50%',
                      height: 'auto',
                      width: '123px'
                    }}
                  ></div>
                </div>
              </div>
              <div
                style={{ height: 'auto', width: '100%', background: '#fff' }}
              >
                {Object.keys(this.state.countStats).length
                  ? Object.keys(this.state.countStats).map(question_key => {
                      return this.render_graph_row(question_key);
                    })
                  : null}
              </div>
            </>
          </Content>
        </Layout>
      </>
    );
  }
}

/*
    key: '1',
    date: moment(value).format('YYYY-MM-DD HH:mm'),
    number_of_plates: 3,
    country: 'Portugal',
    age: ['24_36'],
    answers:1
*/

export default SurveyStatsScreen;
