import React from 'react';
import { Route, NavLink, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { withMedia } from 'react-media-query-hoc';
import { Layout, Menu, Icon } from 'antd';
import { withTranslation } from 'react-i18next';

//pages
import SurveyList from '../backoffice/SurveyList';
import SurveyStatsScreen from '../backoffice/SurveyStatsScreen';

import AppContext from '../../context/AppContextBase';

import { AppMenu as BackofficeMenu } from '../../menu/backoffice';

import globalStyles from '../../styles/global';
import { mrg } from '../../common/util';

const { Header, Content } = Layout;

const styles = {
  mainHeader: {
    height: globalStyles.global.baseline * 6,
    width: '100%',
    backgroundColor: globalStyles.colors.background.queenBlue,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    padding: 0
  },
  pageHeader: {
    height: globalStyles.global.baseline * 4,
    width: '100%',
    backgroundColor: globalStyles.colors.background.queenBlue,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexCenter,
    padding: 0
  },
  menu: {
    fontSize: globalStyles.typography.size.base * 1.5,
    height: globalStyles.global.baseline * 3,
    fontWeight: 300,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: globalStyles.colors.background.queenBlue,
    borderBottom: '1px solid ' + globalStyles.colors.background.queenBlue,
    ...globalStyles.layout.flexHorizontal,
    ...globalStyles.layout.flexStart
  },
  subMenu: {
    backgroundColor: globalStyles.colors.background.queenBlue
  },
  menuItem: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    minWidth: '20%'
  },
  subMenuItem: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100
  },
  menuLink: {
    color: '#fff'
  },
  menuIcon: {
    fontSize: globalStyles.typography.size.base * 1.5,
    fontWeight: 100,
    margin: '0 ' + globalStyles.global.baseline + 'px'
  }
};
class PageMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentMenuItem: this.props.activeKey };
  }
  render() {
    const t = this.props.t;
    return (
      <Menu
        onClick={this.handleClick}
        defaultSelectedKeys={[this.state.currentMenuItem]}
        activeKey={this.state.currentMenuItem}
        mode="horizontal"
        style={styles.menu}
        overflowedIndicator={
          <div style={{ borderBottom: 'unset' }}>
            <Icon style={globalStyles.global.menuIcon} type="more" />
          </div>
        }
      >
        <Menu.Item
          style={mrg([
            styles.menuItem,
            globalStyles.layout.flexHorizontal,
            globalStyles.layout.flexCenter
          ])}
          key="clients"
        >
          <NavLink style={styles.menuLink} exact to="/foodprints/surveylist">
            {this.props.media.mobile || this.props.media.tablet ? (
              <Icon style={styles.menuIcon} type="team" />
            ) : (
              <>
                <Icon style={styles.menuIcon} type="team" />
                {t('menus.example.route1')}
              </>
            )}
          </NavLink>
        </Menu.Item>
        <Menu.Item
          style={mrg([
            styles.menuItem,
            globalStyles.layout.flexHorizontal,
            globalStyles.layout.flexCenter
          ])}
          key="stats"
        >
          <NavLink style={styles.menuLink} to="/foodprints/stats">
            {this.props.media.mobile || this.props.media.tablet ? (
              <Icon style={styles.menuIcon} type="pull-request" />
            ) : (
              <>
                {' '}
                <Icon style={styles.menuIcon} type="pull-request" />
                {t('menus.example.route2')}
              </>
            )}
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  }
}

PageMenu = withTranslation()(withMedia(PageMenu));

class Home extends React.Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    const defaultState = { activeKey: 'clients' };
    const urlComponents = props.location.pathname.split('/');
    if (!urlComponents.length) {
      return defaultState;
    }
    const last = urlComponents[urlComponents.length - 1];
    if (!last || !last.length) {
      return defaultState;
    }
    return { activeKey: last };
  }

  render() {
    const t = this.props.t;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t('pages.example.meta.title')}</title>
        </Helmet>
        <Header style={styles.mainHeader}>
          <BackofficeMenu></BackofficeMenu>
        </Header>
        <Content
          style={{
            height: '100%',
            width: '100%',
            ...globalStyles.layout.flexVertical,
            ...globalStyles.layout.flexCenter
          }}
        >
          <Header style={styles.pageHeader}>
            <PageMenu activeKey={this.state.activeKey} />
          </Header>
          <Content
            style={{
              height:
                'calc( 100vh - ' + globalStyles.global.baseline * 10 + 'px)',
              width: '100%',
              ...globalStyles.layout.flexVertical,
              ...globalStyles.layout.flexCenter
            }}
          >
            <Route
              exact
              path={'/foodprints/surveylist'}
              component={props => <SurveyList />}
              // component={props => (
              //   <div>(Translate me) Your sub route component here</div>
              // )}
            />
            <Route
              exact
              path={'/foodprints/stats'}
              component={props => <SurveyStatsScreen />}
            />
          </Content>
        </Content>
      </>
    );
  }
}

Home.contextType = AppContext;

export default withTranslation()(withMedia(Home));
