// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
  aws_app_analytics: 'disabled',
  aws_cognito_identity_pool_id: '',
  aws_cognito_region: 'us-east-1',
  //aws_project_id: '',
  aws_project_name: 'foodprints_backoffice',
  aws_project_region: 'us-east-1',
  aws_resource_name_prefix: '',
  aws_sign_in_enabled: 'enable',
  aws_user_files: 'enable',
  aws_user_files_s3_bucket: '',
  aws_user_files_s3_bucket_region: '',
  aws_user_pools: 'enable',
  aws_user_pools_id: 'us-east-1_nOixgqFUA',
  aws_user_pools_web_client_id: '1l4crrl83civsk0p2c8l66rr22'
};

export default awsmobile;
