import React from 'react';
import { mrg } from '../../common/util';

const styles = {
  spinner: {
    width: '40px',
    height: '40px',

    position: 'relative',
    margin: '100px auto'
  },

  doubleBounce: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    backgroundColor: '#40a9ff',
    opacity: '0.6',
    position: 'absolute',
    top: '0',
    left: '0',
    WebkitAnimation: 'sk-bounce 2.0s infinite ease-in-out',
    animation: 'sk-bounce 2.0s infinite ease-in-out'
  },

  doubleBounce2: {
    WebkitAnimationDelay: '-1.0s',
    animationDelay: '-1.0s'
  }
};

const Loading = props => (
  <div style={mrg([styles.spinner, props.style])}>
    <div style={styles.doubleBounce} />
    <div style={mrg([styles.doubleBounce, styles.doubleBounce2])} />
    {/* I should get shot for this */}
    <style>
      {`@-webkit-keyframes sk-bounce {
        0%, 100% { -webkit-transform: scale(0.0) }
        50% { -webkit-transform: scale(1.0) }
      }

      @keyframes sk-bounce {
        0%, 100% { 
          transform: scale(0.0);
          -webkit-transform: scale(0.0);
        } 50% { 
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
        }
      }`}
    </style>
  </div>
);

export default Loading;
