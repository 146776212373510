import React from 'react';
import { Button, Card, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import g from '../../styles/global';

import img1 from '../../img/undraw_master_plan.svg';
import card0 from '../../img/pilot.svg';
import card1 from '../../img/user_group.svg';
import card2 from '../../img/password.svg';

const { Meta } = Card;

const styles = {
  container: {
    with: '100%',
    minHeight: '90vh',
    padding: g.global.baseline * 10,
    ...g.layout.flexStart,
    ...g.layout.flexVertical,
    ...g.layout.alignCenter,
    fontSize: g.global.baseline * 1.5,
    fontWeight: 100
  },
  col: {
    height: '100%',
    ...g.layout.flexStart,
    ...g.layout.flexVertical,
    ...g.layout.alignStart
  },
  row: {
    width: '100%',
    paddingBottom: g.global.baseline * 10
  },
  lastRow: {
    paddingBottom: 0
  },
  margin: (n = 1) => ({
    marginBottom: g.global.baseline * n
  }),
  button: {
    color: '#444',
    fontWeight: 100,
    borderColor: '#444'
  },
  title: {
    fontSize: g.global.baseline * 2,
    fontWeight: 500
  }
};

const mkImg = (image, options = { alt: '', height: 'auto', width: '100%' }) => (
  <img
    width={options.width || '100%'}
    height={options.height || 'auto'}
    src={image}
    alt={options.alt || ''}
  ></img>
);

class Content extends React.PureComponent {
  render() {
    const t = this.props.t;
    return (
      <div style={styles.container} id={'why'}>
        <Row
          style={styles.row}
          type="flex"
          justify="center"
          align="middle"
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col style={styles.col} span={8}>
            {mkImg(img1, { alt: 'life planning' })}
          </Col>
          <Col style={styles.col} span={16}>
            <div style={{ ...styles.title, ...styles.margin(2) }}>
              {t('pages.landing.content.cta.title')}
            </div>
            <div style={styles.margin(3)}>
              {t('pages.landing.content.cta.content')}
            </div>
            <Button ghost type="secondary" style={styles.button}>
              {t('pages.landing.hero.learn')}
            </Button>
          </Col>
        </Row>
        <br></br>
        <Row
          id={'how'}
          style={{ ...styles.row, ...styles.lastRow }}
          type="flex"
          justify="center"
          align="middle"
          gutter={g.global.baseline * 6}
        >
          <Col span={8}>
            <Card
              bordered={false}
              style={{ width: '100%' }}
              cover={mkImg(card0, {
                height: g.global.baseline * 12,
                width: 'auto'
              })}
            >
              <Meta
                title={t('pages.landing.content.cards.0.title')}
                description={t('pages.landing.content.cards.0.content')}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              bordered={false}
              style={{ width: '100%' }}
              cover={mkImg(card1, {
                height: g.global.baseline * 12,
                width: 'auto'
              })}
            >
              <Meta
                title={t('pages.landing.content.cards.1.title')}
                description={t('pages.landing.content.cards.1.content')}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card
              bordered={false}
              style={{ width: '100%' }}
              cover={mkImg(card2, {
                height: g.global.baseline * 12,
                width: 'auto'
              })}
            >
              <Meta
                title={t('pages.landing.content.cards.2.title')}
                description={t('pages.landing.content.cards.2.content')}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withTranslation()(Content);
