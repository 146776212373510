import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import validator from 'validator';

import AppContext from '../../context/AppContextBase';
import DetailedMessage from '../../components/util/DetailedMessage';
import g from '../../styles/global';

import logo from '../../img/pilot.svg';

const FormItem = Form.Item;

const base = g.global.baseline;
const gutter = base * 2;

const styles = {
  title: {
    fontWeight: 100,
    padding: g.global.baseline,
    paddingLeft: 0,
    marginBottom: 0
  },
  error: {
    color: 'red'
  },
  fullWidth: {
    width: '100%'
  },
  size: {
    half: {
      width: `calc(50% - ${gutter}px)`
    },
    third: {
      width: `calc(33% - ${gutter}px)`
    },
    width: `calc(20% - ${gutter}px)`
  },

  container: {
    logo: {
      ...g.layout.flexHorizontal,
      ...g.layout.flexCenter,
      ...g.layout.alignCenter,
      width: '100%',
      height: '100%',
      padding: g.global.baseline * 6
    },
    form: {
      ...g.layout.flexVertical,
      ...g.layout.flexCenter,
      ...g.layout.alignStart,
      width: '100%',
      height: '100%',
      padding: g.global.baseline * 2
    }
  }
};

class Login extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.state = {
      signState: 'register',
      confirmDirty: false,
      loading: false,
      confirmError: false
    };
    this.successContent = this.props.successContent || this.getSuccessContent();
    this.confirmContent = this.props.confirmContent || this.getConfirmContent();
    console.warn('TRANSLATE ME');
  }

  getConfirmContent() {
    const { getFieldDecorator } = this.props.form;
    const t = this.props.t;
    const self = this;
    return (
      <>
        <Form style={styles.container.form} onSubmit={this.handleConfirm}>
          <h2 style={styles.title}>Check your inbox</h2>
          <Row
            type="flex"
            align="middle"
            justify="start"
            style={{
              height: '100%',
              width: '100%',
              paddingBottom: g.global.baseline
            }}
          >
            <div>We have sent you an e-mail with a confirmation code</div>
          </Row>
          <Row
            type="flex"
            gutter={gutter}
            align="middle"
            justify="start"
            style={{
              height: '100%',
              width: '100%',
              paddingBottom: 0
            }}
          >
            <Col span={10}>
              <FormItem style={{}}>
                {getFieldDecorator('confirm-username', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    }
                  ],
                  initialValue: this.state.username
                })(
                  <Input
                    style={styles.fullWidth}
                    disabled={this.state.loading}
                    prefix={
                      <Icon
                        type="user"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    placeholder="Username"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={10}>
              <FormItem style={{}}>
                {getFieldDecorator('confirmation_code', {
                  rules: [
                    {
                      required: true,
                      message: 'Required'
                    },
                    {
                      validator: async (rule, value, callback) => {
                        if (self.state.confirmError) {
                          callback(self.state.confirmError);
                        } else {
                          callback();
                        }
                      }
                    }
                  ]
                })(
                  <Input
                    style={styles.fullWidth}
                    disabled={this.state.loading}
                    prefix={
                      <Icon
                        type="qrcode"
                        style={{
                          color: 'rgba(0,0,0,.25)'
                        }}
                      />
                    }
                    placeholder="Confirmation Code"
                    onChange={e => {
                      //log(e.target.value);
                      return this.setState({
                        firstname: e.target.value.trim()
                      });
                    }}
                  />
                )}
              </FormItem>
            </Col>

            <Col span={4}>
              <FormItem style={{}}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{}}
                  loading={this.state.loading}
                  //setFieldsValue={this.state.username}
                >
                  {t('pages.register.confirm')}
                </Button>
              </FormItem>
            </Col>
          </Row>
          <Row
            type="flex"
            gutter={gutter}
            align="middle"
            justify="start"
            style={{
              height: '100%',
              width: '100%',
              paddingBottom: g.global.baseline
            }}
          >
            <Col span={24}>
              <span>
                Did not receive our e-mail?
                <Button
                  type="link"
                  onClick={async () => {
                    await this.props.form.validateFields(
                      ['username'],
                      { force: true },
                      async (errors, values) => {
                        if (!errors) {
                          try {
                            await this.context.resendSignUp(
                              this.state.username
                            );
                          } catch (err) {
                            this.setState({
                              confirmError:
                                'Something went wrong. Please try again.'
                            });
                            console.error(err);
                          }
                        }
                      }
                    );
                  }}
                >
                  Resend confirmation code
                </Button>
              </span>
            </Col>
          </Row>
        </Form>
      </>
    );
  }

  getSuccessContent() {
    return (
      <div style={styles.container.form}>
        <h2 style={styles.title}>Welcome!</h2>
        <span>
          You've successfully registered in Emcomy! You can now head to the{' '}
          <Link to="/login">the login page</Link> and start using the app. We're
          glad to have you here!
        </span>
      </div>
    );
  }

  getRegisterContent() {
    const { getFieldDecorator } = this.props.form;
    const t = this.props.t;
    return (
      <Form style={styles.container.form} onSubmit={this.handleSubmit}>
        <h2 style={styles.title}>Let's get you onboarded!</h2>
        <Row
          type="flex"
          gutter={gutter}
          align="middle"
          justify="start"
          style={{ height: '100%', width: '100%' }}
        >
          <Col span={8}>
            <FormItem style={{}}>
              {getFieldDecorator('given_name', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  },
                  { min: 2, message: 'Too short' }
                ]
              })(
                <Input
                  style={styles.fullWidth}
                  disabled={this.state.loading}
                  prefix={
                    <Icon
                      type="user"
                      style={{
                        color: 'rgba(0,0,0,.25)'
                      }}
                    />
                  }
                  placeholder="First Name"
                  onChange={e => {
                    //log(e.target.value);
                    return this.setState({
                      firstname: e.target.value.trim()
                    });
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem style={{}}>
              {getFieldDecorator('family_name', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  },
                  { min: 2, message: 'Too short' }
                ]
              })(
                <Input
                  style={styles.fullWidth}
                  disabled={this.state.loading}
                  prefix={
                    <Icon
                      type="user"
                      style={{
                        color: 'rgba(0,0,0,.25)'
                      }}
                    />
                  }
                  placeholder="Last Name"
                  onChange={e => {
                    //log(e.target.value);
                    return this.setState({
                      lastname: e.target.value.trim()
                    });
                  }}
                />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row
          type="flex"
          gutter={gutter}
          align="middle"
          justify="start"
          style={{ height: '100%', width: '100%' }}
        >
          <Col span={8}>
            <FormItem style={{}}>
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  },
                  { min: 3, message: 'Too short' }
                ]
              })(
                <Input
                  style={styles.fullWidth}
                  disabled={this.state.loading}
                  prefix={
                    <Icon
                      type="user"
                      style={{
                        color: 'rgba(0,0,0,.25)'
                      }}
                    />
                  }
                  placeholder="Username"
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem style={{}}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  },
                  {
                    validator: async (rule, value, callback) => {
                      if (value && !validator.isEmail(value)) {
                        callback('Invalid Email');
                      } else {
                        callback();
                      }
                    }
                  }
                ]
              })(
                <Input
                  style={styles.fullWidth}
                  disabled={this.state.loading}
                  prefix={
                    <Icon
                      type="mail"
                      style={{
                        color: 'rgba(0,0,0,.25)'
                      }}
                    />
                  }
                  placeholder="E-mail"
                  onChange={e => {
                    //log(e.target.value);
                    return this.setState({
                      email: e.target.value.trim()
                    });
                  }}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row
          type="flex"
          gutter={gutter}
          align="middle"
          justify="start"
          style={{ height: '100%', width: '100%' }}
        >
          <Col span={12}>
            <FormItem key="password">
              {getFieldDecorator('password', {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  },
                  {
                    validator: this.checkConfirm
                  }
                ]
              })(
                <Input.Password
                  disabled={this.state.loading}
                  prefix={
                    <Icon
                      type="lock"
                      style={{
                        color: 'rgba(0,0,0,.25)'
                      }}
                    />
                  }
                  type="password"
                  placeholder="Password"
                  onBlur={this.handleConfirmBlur}
                  onChange={e => {
                    return this.setState({
                      password: e.target.value.trim()
                    });
                  }}
                />
              )}
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem key="confirm-password">
              {getFieldDecorator('confirm-password', {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: 'Required'
                  },
                  {
                    validator: this.checkPassword
                  }
                ]
              })(
                <Input.Password
                  disabled={this.state.loading}
                  prefix={
                    <Icon
                      type="lock"
                      style={{
                        color: 'rgba(0,0,0,.25)'
                      }}
                    />
                  }
                  type="password"
                  placeholder="Confirm password"
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row
          type="flex"
          gutter={gutter}
          align="middle"
          justify="start"
          style={{
            height: '100%',
            width: '100%',
            paddingBottom: g.global.baseline
          }}
        >
          <Col span={24}>
            <span>
              Already have a confirmation code?{' '}
              <Button
                type="link"
                onClick={async () => {
                  this.setState({ signState: 'confirm' });
                }}
              >
                Skip to confirmation page
              </Button>
            </span>
          </Col>
        </Row>
        <FormItem style={{}}>
          <Button
            type="primary"
            htmlType="submit"
            style={{}}
            loading={this.state.loading}
            //setFieldsValue={this.state.username}
          >
            {t('pages.register.register')}
          </Button>
        </FormItem>
      </Form>
    );
  }

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Passwords do not match');
    } else {
      callback();
    }
  };

  checkConfirm = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm-password'], { force: true });
    }
    callback();
  };

  handleConfirm = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        try {
          await this.context.confirmSignUp(
            values['confirm-username'],
            values.confirmation_code
          );

          this.setState({
            username: null,
            password: null,
            loading: false,
            confirmError: false,
            signState: 'success'
          });
        } catch (err) {
          console.log(err);
          this.setState({
            loading: false,
            confirmError: err && err.message ? err.message : 'Invalid Code!'
          });
          this.props.form.validateFields(['confirmation_code'], {
            force: true
          });
        }
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true });
        for (const key in values) {
          values[key] = values[key].trim();
        }
        try {
          const signUpResult = await this.context.signUp(
            values.username,
            values.email,
            values.password,
            values.given_name,
            values.family_name
          );
          console.log('Signed up:', signUpResult);
          this.setState({
            signState: 'confirm',
            username: values.username,
            password: values.password
          });
        } catch (e) {
          DetailedMessage.error(
            'Unexpected Error \n' + (e && e.message ? e.message : ''),
            e
          );
        }
        this.setState({ loading: false });
      }
    });
  };

  getContent = () => {
    switch (this.state.signState) {
      case 'success':
        return this.getSuccessContent();
      case 'confirm':
        return this.getConfirmContent();
      default:
        return this.getRegisterContent();
    }
  };

  render() {
    return (
      <Row
        type="flex"
        gutter={gutter}
        align="middle"
        justify="center"
        style={{ height: '100%', width: '100%' }}
      >
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          <div style={styles.container.logo}>
            <img src={logo} alt="logo"></img>
          </div>
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }}>
          {this.getContent()}
        </Col>
      </Row>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(Login);

export default withTranslation()(WrappedNormalLoginForm);
