import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({
  authenticated,
  component: Component,
  redirect,
  path,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      path={path}
      render={props => {
        if (authenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect to={{ pathname: redirect, state: { redirect: path } }} />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
