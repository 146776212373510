import React from 'react';
import { Col, Input, Icon, DatePicker, Select } from 'antd';

import globalStyles from '../../styles/global';
import { mrg } from '../../common/util';
import { tryParseDate } from '../../common/util';

const { Option } = Select;

const styles = {
  statistic: {
    container: {
      fontSize: globalStyles.global.baseline,
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexStart,
      ...globalStyles.layout.alignCenter,
      marginBottom: globalStyles.global.baseline,
      maxWidth: '100%'
    },
    title: {
      fontWeight: 900,
      color: '#666666',
      marginRight: globalStyles.global.baseline
    },
    value: {
      fontWeight: 300
    }
  },
  column: {
    ...globalStyles.layout.flexVertical,
    ...globalStyles.layout.flexBetween,
    ...globalStyles.layout.alignStart,
    paddingRight: globalStyles.global.baseline
  },
  addon: {
    before: {
      position: 'relative',
      padding: '0 11px',
      color: '#444',
      width: '100%',
      fontWeight: 'normal',
      fontSize: '12px',
      backgroundColor: '#fafafa',
      border: '1px solid #d9d9d9',
      borderBottom: 'unset',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      lineHeight: 1.5,
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexBetween,
      ...globalStyles.layout.alignCenter
    },
    after: {
      position: 'relative',
      padding: '0 11px',
      color: '#444',
      fontWeight: 'normal',
      fontSize: '12px',
      textAlign: 'center',
      backgroundColor: '#fafafa',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      borderLeft: '0',
      lineHeight: 2.5,
      ...globalStyles.layout.flexHorizontal,
      ...globalStyles.layout.flexBetween,
      ...globalStyles.layout.alignCenter
    },
    beforeInline: {
      position: 'relative',
      padding: '0 11px',
      color: '#444',
      fontWeight: 'normal',
      fontSize: '12px',
      textAlign: 'center',
      backgroundColor: '#fafafa',
      border: '1px solid #d9d9d9',
      borderRight: 'unset',
      borderRadius: '4px',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      lineHeight: 1.5,
      whiteSpace: 'nowrap'
    }
  }
};

class EditableField extends React.Component {
  static Types = {
    textarea: 'textarea',
    date: 'date',
    title: 'title',
    select: 'select'
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      dirty: false,
      saving: false,
      value: props.editable ? props.value : undefined
    };
    this.getElement = this.getElement.bind(this);
    this.getInputProps = this.getInputProps.bind(this);
    this.onChangeEvent = this.onChangeEvent.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.addonBefore = this.addonBefore.bind(this);
    this.addonAfter = this.addonAfter.bind(this);
    this.getAddons = this.getAddons.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value && this.props.editable) {
      this.setState({ value: this.props.value });
    }
  }

  getElement() {
    let Element;
    let elementStyle;
    switch (this.props.type) {
      case EditableField.Types.textarea:
        Element = Input.TextArea;
        elementStyle = this.props.editable
          ? { width: '100%' }
          : { maxWidth: '100%', ...styles.statistic.container };
        if (!this.props.hideElementBefore) {
          elementStyle.borderTopLeftRadius = 0;
          elementStyle.borderTopRightRadius = 0;
        }
        break;

      case EditableField.Types.select:
        if (this.props.editable) {
          Element = props => {
            const opts = this.props.options;
            return (
              <Select {...props}>
                {opts.map(opt => (
                  <Option key={opt.key}>{opt.value}</Option>
                ))}
              </Select>
            );
          };

          elementStyle = {
            borderRadius: 0,
            width: '100%'
          };
        } else {
          // if not editable default to regular input
          Element = Input;
          elementStyle = { width: '100%' };
        }
        break;

      case EditableField.Types.date:
        if (this.props.editable) {
          Element = DatePicker;
          elementStyle = { width: '100%', minWidth: 'unset' };
          elementStyle.borderRadius = '0px';
        } else {
          // if not editable default to regular input
          Element = Input;
          elementStyle = { width: '100%', ...styles.statistic.container };
        }

        break;
      case EditableField.Types.title:
        Element = props => <h2 {...props}>{props.title}</h2>;
        elementStyle = { width: '100%', borderBottom: '1px solid #eaebeb' };
        break;
      default:
        Element = Input;
        elementStyle = this.props.editable
          ? { width: '100%' }
          : { maxWidth: '100%', ...styles.statistic.container };
    }

    return { Element, elementStyle };
  }

  async onChangeEvent(e) {
    this.setState({ value: e.target.value, dirty: true });
    if (this.props.onChange) {
      return await this.props.onChange(e);
    }
  }

  async onChangeValue(date) {
    this.setState({ value: date, dirty: true });
    if (this.props.onChange) {
      return await this.props.onChange(date);
    }
  }

  getInputProps() {
    let inputProps = {};

    switch (this.props.type) {
      case EditableField.Types.title:
        inputProps = { title: this.props.title };
        break;
      case EditableField.Types.textarea:
        // base props
        inputProps = {
          autosize: this.props.autosize ? this.props.autosize : { minRows: 4 },
          value: this.props.value
        };
        // props when editable
        if (this.props.editable) {
          inputProps.value = this.state.value;
          inputProps.onChange = this.onChangeEvent;
        }
        break;
      case EditableField.Types.select:
        // base props

        // props when editable
        if (this.props.editable) {
          inputProps = {
            showSearch: true,
            placeholder: '',
            className: 'no-radius',
            filterOption: (input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
          };
          inputProps.value = this.state.value;
          inputProps.onChange = this.onChangeValue;
        } else {
          // if not editable, default to regular input
          inputProps = {
            value: this.props.value,
            addonBefore: this.props.hideElementBefore
              ? null
              : this.addonBefore()
          };
          // props when editable
          if (this.props.editable) {
            inputProps.value = this.state.value;
            inputProps.onChange = this.onChangeEvent;
            // props when editable and hideIcon
            if (!this.props.hideIcon) {
              inputProps.addonAfter = this.addonAfter();
            }
          }
        }
        break;
      case EditableField.Types.date:
        // base props

        // props when editable
        if (this.props.editable) {
          inputProps = {
            value: tryParseDate(this.props.value, {
              nullable: true
            }),
            showTime: true,
            placeholder: '',
            className: 'no-radius'
          };
          // if we're editable, we'll manage this in our state. Otherwise, let DatePicker manage itself
          inputProps.value = tryParseDate(this.state.value, {
            nullable: true
          });
          inputProps.onChange = this.onChangeValue;

          if (!this.props.hideIcon) {
            inputProps.addonAfter = this.addonAfter();
          }
        } else {
          // if not editable, default to regular input
          inputProps = {
            value: this.props.value,
            addonBefore: this.props.hideElementBefore
              ? null
              : this.addonBefore()
          };
          // props when editable
          if (this.props.editable) {
            inputProps.value = this.state.value;
            inputProps.onChange = this.onChangeEvent;
            // props when editable and hideIcon
            if (!this.props.hideIcon) {
              inputProps.addonAfter = this.addonAfter();
            }
          }
        }
        break;
      default:
        // base props
        inputProps = {
          value: this.props.value,
          addonBefore: this.props.hideElementBefore ? null : this.addonBefore()
        };
        // props when editable
        if (this.props.editable) {
          inputProps.value = this.state.value;
          inputProps.onChange = this.onChangeEvent;
          // props when editable and hideIcon
          if (!this.props.hideIcon) {
            inputProps.addonAfter = this.addonAfter();
          }
        }
    }

    //disabled is the same for all field types:
    inputProps.disabled = this.props.editable
      ? this.state.saving || this.props.disabled
      : this.props.disabled;
    return inputProps;
  }

  addonBefore() {
    return (
      <div
        style={{
          minWidth: this.props.useMinWidth ? '6rem' : 0,
          lineHeight: 2.5
        }}
      >
        {this.props.title}
      </div>
    );
  }
  addonAfter() {
    return (
      <div
        style={{
          lineHeight: 2.5
        }}
      >
        <Icon
          type={this.state.saving ? 'loading' : 'save'}
          style={{ color: '#40a9ff' }}
          theme={this.state.dirty ? 'filled' : 'outlined'}
          onClick={async () => {
            this.setState({ saving: true });
            let result = null;
            try {
              if (this.props.onSave) {
                result = await this.props.onSave(this.state.value);
              }
              if (result.result === 'OK' || result.ok) {
                //this (result === OK ) must be refactored
                if (this.props.onSaveSuccess) {
                  this.setState({ saving: false });
                  return await this.props.onSaveSuccess(result);
                }
              } else {
                throw result; //hack to be compatible with old code (result can be 404 and not exception error) -  works fine
              }
            } catch (err) {
              if (this.props.onSaveError) {
                this.setState({ saving: false });
                return await this.props.onSaveError(err);
              }
            }
            this.setState({ dirty: false });
            //This below, was savnig every time - error or not error
            // if (this.props.onSaveSuccess) {
            //   this.setState({ saving: false });
            //   return await this.props.onSaveSuccess(result);
            // }
          }}
        />
      </div>
    );
  }

  getAddons() {
    let elementBefore = null;
    let elementAfter = null;

    switch (this.props.type) {
      case EditableField.Types.date:
        if (this.props.editable) {
          elementBefore = (
            <div style={styles.addon.beforeInline}>{this.addonBefore()}</div>
          );
          elementAfter = this.props.editable ? (
            <div style={styles.addon.after}>{this.addonAfter()}</div>
          ) : null;
        }
        break;
      case EditableField.Types.select:
        if (this.props.editable) {
          elementBefore = (
            <div style={styles.addon.beforeInline}>{this.addonBefore()}</div>
          );
          elementAfter = this.props.editable ? (
            <div style={styles.addon.after}>{this.addonAfter()}</div>
          ) : null;
        }
        break;
      case EditableField.Types.textarea:
        elementBefore = (
          <div style={styles.addon.before}>
            {this.addonBefore()}
            {this.props.editable && !this.props.hideIcon && this.addonAfter()}
          </div>
        );
        break;
      default:
        break;
    }

    if (this.props.hideElementBefore) {
      elementBefore = null;
    }

    if (this.props.hideIcon) {
      elementAfter = null;
    }

    return { elementBefore, elementAfter };
  }

  render() {
    const { Element, elementStyle } = this.getElement();
    const { elementBefore, elementAfter } = this.getAddons();
    const inputProps = this.getInputProps();

    if (this.props.editable) {
      return (
        <Col
          style={{
            ...styles.column,
            ...this.props.style
          }}
          key={this.props.title}
          span={this.props.span || 8}
        >
          <div
            style={mrg([
              styles.statistic.container,
              { width: '100%' },
              this.props.type === EditableField.Types.textarea
                ? globalStyles.layout.flexVertical
                : {},
              this.props.innerStyle
            ])}
          >
            {elementBefore}
            <Element style={elementStyle} {...inputProps} />
            {elementAfter}
          </div>
        </Col>
      );
    } else {
      return (
        <Col
          style={{
            ...styles.column,
            ...this.props.style
          }}
          key={this.props.title}
          span={this.props.span || 8}
        >
          {this.props.value && (
            <>
              {elementBefore}
              <Element style={elementStyle} {...inputProps} />
              {elementAfter}
            </>
          )}
        </Col>
      );
    }
  }
}

export default EditableField;
